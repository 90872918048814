import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, InputNumber, Radio, Switch, Select } from 'antd';
import { AddPackage, getPackageDetail, UpdatePackage } from "../../action/authAction";
import { getLanguageList } from "../../action/authAction"

class NewPackage extends React.Component {
    state = {
        data: "",
        is_have_h5: true,
        is_langs: true,
        is_own_payment: true,
        is_authorization_code: true,
        is_show: true,
        is_have_domain: true,
        is_scan_code: true,
        LanguageData: ""
    }
    async componentDidMount() {

        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({
            LanguageData
        }, () => {
            this.handleGetPackageDetail(LanguageData[1].id)
        })
    }


    handleGetPackageDetail = (language_id) => {
        const id = this.props.location.pathname.substring(12)
        if (id) {
            getPackageDetail(id, language_id).then(res => {
                this.setState({
                    data: res.data,
                    is_have_h5: res.data.is_have_h5 === 1 ? true : false,
                    is_langs: res.data.is_langs === 1 ? true : false,
                    is_own_payment: res.data.is_own_payment === 1 ? true : false,
                    is_authorization_code: res.data.is_authorization_code === 1 ? true : false,
                    is_show: res.data.is_show === 1 ? true : false,
                    is_have_domain: res.data.is_have_domain === 1 ? true : false,
                    is_scan_code: res.data.is_scan_code === 1 ? true : false,
                })
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const id = this.props.location.pathname.substring(12)
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.is_have_h5 = this.state.is_have_h5 ? "1" : "0"
            values.is_langs = this.state.is_langs ? "1" : "0"
            values.is_own_payment = this.state.is_own_payment ? "1" : "0"
            values.is_authorization_code = this.state.is_authorization_code ? "1" : "0"
            values.is_show = this.state.is_show ? "1" : "0"
            values.is_have_domain = this.state.is_have_domain ? "1" : "0"
            values.is_scan_code = this.state.is_scan_code ? "1" : "0"
            if (id) {
                UpdatePackage(values, id).then(res => {
                    // this.props.history.push("/PackageList")
                })
            } else {
                AddPackage(values).then(res => {
                    // this.props.history.push("/PackageList")
                })
            }
        });
    };

    render() {
        let { data, is_have_h5, is_langs, is_own_payment, is_authorization_code, is_show, is_have_domain, is_scan_code, LanguageData } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>
                <Form.Item
                    label="选择录入的语言"
                >
                    {getFieldDecorator('request_language_id', {
                        rules: [{ required: true, message: '请选择语言!' }],
                        initialValue: LanguageData && LanguageData[1].id
                    })(
                        <Select
                            onChange={(e) => {
                                this.handleGetPackageDetail(e)
                            }}
                        >
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label="套餐名称"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请输入套餐名称', whitespace: true }],
                        initialValue: data.name
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label="初始费"
                >
                    {getFieldDecorator('setup_fee', {
                        rules: [{ required: false, message: '请输入初始费', whitespace: true }],
                        initialValue: data.setup_fee
                    })(<Input prefix="$" />)}
                </Form.Item>
                <Form.Item
                    label="年费"
                >
                    {getFieldDecorator('year_service_fee', {
                        rules: [{ required: false, message: '请输入年费', whitespace: true }],
                        initialValue: data.year_service_fee
                    })(<Input prefix="$" />)}
                </Form.Item>


                <Form.Item
                    label="年达标单数"
                >
                    {getFieldDecorator('year_service_fee_by_order_num', {
                        rules: [{ required: false, message: '请输入年达标单数' }],
                        initialValue: data.year_service_fee_by_order_num
                    })(<Input />)}
                </Form.Item>




                <Form.Item
                    label="月费"
                >
                    {getFieldDecorator('month_service_fee', {
                        rules: [{ required: false, message: '请输入月费' }],
                        initialValue: data.month_service_fee
                    })(<Input prefix="$" />)}
                </Form.Item>
                <Form.Item
                    label="月达标单数"
                >
                    {getFieldDecorator('month_service_fee_by_order_num', {
                        rules: [{ required: false, message: '请输入月达标单数' }],
                        initialValue: data.month_service_fee_by_order_num
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label="月平台活动达标单数"
                >
                    {getFieldDecorator('money_standard_activity_order_num', {
                        rules: [{ required: false, message: '请输入月平台活动达标单数' }],
                        initialValue: data.money_standard_activity_order_num
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label="签约期（月）"
                >
                    {getFieldDecorator('contract_date', {
                        rules: [{ required: false, message: '请输入签约期' }],
                        initialValue: data.contract_date
                    })(<Input />)}
                </Form.Item>


                
                <Form.Item
                    label="签约期达标单数"
                >
                    {getFieldDecorator('contract_term_standard_order_num', {
                        rules: [{ required: false, message: '请输入签约期达标单数' }],
                        initialValue: data.contract_term_standard_order_num
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label="签约期活动达标单数"
                >
                    {getFieldDecorator('contract_term_standard_activity_order_num', {
                        rules: [{ required: false, message: '请输入签约期活动达标单数' }],
                        initialValue: data.contract_term_standard_activity_order_num
                    })(<Input />)}
                </Form.Item>


                



                <Form.Item
                    label="定额订单服务费"
                >
                    {getFieldDecorator('order_fixed_fee', {
                        rules: [{ required: false, message: '定额订单服务费', whitespace: true }],
                        initialValue: data.order_fixed_fee
                    })(<Input prefix="$" />)}
                </Form.Item>
                <Form.Item
                    label="商品订单服务费"
                >
                    {getFieldDecorator('rate', {
                        rules: [{ required: false, message: '商品订单服务费' }],
                        initialValue: data.rate
                    })(<Input min={0} style={{ width: '100%' }} suffix="%" />)}
                </Form.Item>
                <Form.Item
                    label="活动订单服务费"
                >
                    {getFieldDecorator('activity_service_rate', {
                        rules: [{ required: false, message: '活动订单服务费' }],
                        initialValue: data.activity_service_rate
                    })(<Input min={0} style={{ width: '100%' }} suffix="%" />)}
                </Form.Item>
                <Form.Item
                    label="体验期（月）"
                >
                    {getFieldDecorator('trial_period_days', {
                        initialValue: data.trial_period_days
                    })(<InputNumber min={0} style={{ width: '100%' }} />)}
                </Form.Item>
                <Form.Item
                    label="体验期定额订单服务费"
                >
                    {getFieldDecorator('fee_reduction_fixed_fee', {
                        initialValue: data.fee_reduction_fixed_fee
                    })(<Input min={0} style={{ width: '100%' }} prefix="$" />)}
                </Form.Item>

                <Form.Item
                    label="体验期商品订单服务费"
                >
                    {getFieldDecorator('fee_reduction_rate', {
                        initialValue: data.fee_reduction_rate
                    })(<Input min={0} style={{ width: '100%' }} suffix="%" />)}
                </Form.Item>

                <Form.Item
                    label="体验期活动订单服务费"
                >
                    {getFieldDecorator('fee_reduction_activity_service_rate', {
                        initialValue: data.fee_reduction_activity_service_rate
                    })(<Input min={0} style={{ width: '100%' }} suffix="%" />)}
                </Form.Item>
                <Form.Item
                    label="预存金额"
                >
                    {getFieldDecorator('prestore_money', {
                        initialValue: data.prestore_money
                    })(<Input min={0} style={{ width: '100%' }} prefix="$" />)}
                </Form.Item>
                <Form.Item
                    label="结算周期"
                >
                    {getFieldDecorator('payment_period', {
                        initialValue: data.payment_period
                    })(<Input min={0} style={{ width: '100%' }} />)}
                </Form.Item>

                <Form.Item
                    label="排序"
                >
                    {getFieldDecorator('sort', {
                        initialValue: data.sort
                    })(<InputNumber min={0} style={{ width: '100%' }} />)}
                </Form.Item>
                <Form.Item
                    label="商品数量"
                >
                    {getFieldDecorator('spu_quantity', {
                        initialValue: data.spu_quantity
                    })(<InputNumber style={{ width: '100%' }} />)}
                </Form.Item>
                <Form.Item
                    label="优惠券ticket数量"
                >
                    {getFieldDecorator('coupon_quantity', {
                        initialValue: data.coupon_quantity
                    })(<InputNumber style={{ width: '100%' }} />)}
                </Form.Item>
                <Form.Item
                    label="简介"
                >
                    {getFieldDecorator('remark', {
                        initialValue: data.remark
                    })(<Input style={{ width: '100%' }} />)}
                </Form.Item>
                <Form.Item
                    label="是否显示"
                >
                    <Switch checked={is_show} onChange={() => {
                        this.setState({
                            is_show: !is_show
                        })
                    }} />
                </Form.Item>
                <Form.Item
                    label="开启授权"
                >
                    <Switch checked={is_authorization_code} onChange={() => {
                        this.setState({
                            is_authorization_code: !is_authorization_code
                        })
                    }} />
                </Form.Item>
                <Form.Item
                    label="支持独立结算"
                >
                    <Switch checked={is_own_payment} disabled onChange={() => {
                        this.setState({
                            is_own_payment: !is_own_payment
                        })
                    }} />
                </Form.Item>
                <Form.Item
                    label="支持商家多语言"
                >
                    <Switch checked={is_langs} disabled onChange={() => {
                        this.setState({
                            is_langs: !is_langs
                        })
                    }} />
                </Form.Item>

                <Form.Item
                    label="支持H5移动端浏览器"
                >
                    <Switch checked={is_have_h5} disabled onChange={() => {
                        this.setState({
                            is_have_h5: !is_have_h5
                        })
                    }} />
                </Form.Item>
                <Form.Item
                    label="支持独立域名"
                >
                    <Switch checked={is_have_domain} disabled onChange={() => {
                        this.setState({
                            is_have_domain: !is_have_domain
                        })
                    }} />
                </Form.Item>

                {/*
                <Form.Item
                    label="支持立牌"
                >
                    <Switch checked={is_scan_code} disabled onChange={() => {
                        this.setState({
                            is_scan_code: !is_scan_code
                        })
                    }} />
                </Form.Item>
                */}




                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewPackage))
