import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, Switch ,Spin} from 'antd';
import { AddSalesAreaSub, queryAddress,getSalesAreaList } from "../../action/shopAction";
let { Option } = Select
let lastFetchId = 0;

class NewSalesAreaSub extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            SalesAreaList: "",
            fetching: false,
            Subs: [],
            SuburbList: [],
            SearchSuburbsTag: [],
            is_use: false
        }
    }

    componentDidMount() {
        getSalesAreaList().then(res => {
            this.setState({
                SalesAreaList: res.data.data
            })
        })
    }

    handleSubmit = (e) => {

        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {


                //补充tag
                let { SearchSuburbsTag } = this.state
                let subs = ""
                for (let i in SearchSuburbsTag) {
                    if (subs) {
                        subs = subs + "," + SearchSuburbsTag[i]
                    } else {
                        subs = SearchSuburbsTag[i]
                    }

                }
                values.subs = subs 


        
                values.is_use = this.state.is_use ? "1" : "0"
                AddSalesAreaSub(values).then(res => {
                    this.props.history.goBack()
                })
            }

        });
    };

    render() {
        let {SearchSuburbsTag, fetching,SalesAreaList, is_use, Subs ,SuburbList} = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        const fetchSuburb = (value) => {

            lastFetchId += 1;
            const fetchId = lastFetchId;
            this.setState({
                fetching: true
            })
            queryAddress(value).then((res) => {
                if (fetchId !== lastFetchId) {
                    return;
                }
                this.setState({
                    SuburbList: [...res.data],
                    fetching: true
                })
            });

        };




        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label="销售大区"
                >
                    {getFieldDecorator('sales_area_id', {
                        rules: [{ required: true, message: '请选择销售大区!' }],
                    })(
                        <Select
                            placeholder="请选择销售大区"
                            onChange={(e, value) => {
                                let data = value.props.data.subs
                                this.setState({
                                    Subs: data.split(",")
                                })
                            }}
                        >
                            {SalesAreaList && SalesAreaList.map(el => <Select.Option key={el.id} value={el.id} data={el}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>


                <Form.Item
                    label="小区名称"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: "请输入名称", whitespace: true }],
                    })(<Input placeholder='请输入小区名称' />)}
                </Form.Item>


                


                {/*
                <Form.Item
                    label="Sub"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请选择sub!' }],
                    })(
                        <Select placeholder="请选择sub">
                            {Subs && Subs.map(el => <Select.Option key={el} value={el}>{el}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
                */}




                <Form.Item
                    label="选择sub"
                >
                    {getFieldDecorator('subs', {
                        rules: [{ required: false, message: '请选择sub!' }],
                    })(
                        <Select
                            mode="multiple"
                            placeholder="Select Suburb"
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            value={SearchSuburbsTag}
                            filterOption={false}
                            onSearch={fetchSuburb}
                            onChange={(value) => {
                                this.setState({
                                    SearchSuburbsTag: value
                                })
                            }}
                        >
                            {SuburbList.map((item, index) => {
                                return (
                                    <Option
                                        key={index + item.postcode + item.name}
                                        //value={item.state.abbreviation + ' ' + item.name + ' ' + item.postcode}
                                        value={item.name}
                                        id={index}
                                    >
                                        {/*item.state.abbreviation + ' ' + item.name + ' ' + item.postcode*/}
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    )}
                </Form.Item>






                <Form.Item label="是否启用">
                    <Switch checked={is_use} onChange={() => {
                        this.setState({
                            is_use: !is_use
                        })
                    }} />
                </Form.Item> 
                









                <Form.Item
                    label="排序"
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: 0
                    })(<Input />)}
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewSalesAreaSub))
