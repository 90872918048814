import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch, Input, Tooltip, Popover, Icon ,Select} from 'antd';
import { getGoodsList, deleteGoods, putGoods, getCategory } from "../../action/goodAction";
import { getPartner, UpdateShopMessage } from "../../action/authAction"
import { getShopList, getShopDetail } from "../../action/shopAction"
import {getLanguageList } from "../../action/authAction";

import intl from 'react-intl-universal';
const { Search } = Input; 
const { Option } = Select;

let currency_symbol = sessionStorage.getItem("currency_symbol")


const fileIcon = () => (
    <svg t="1672985602944" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1282" width="16" height="16"><path d="M143 398v415h737V398H143z m-80-48c0-17.673 14.327-32 32-32h833c17.673 0 32 14.327 32 32v511c0 17.673-14.327 32-32 32H95c-17.673 0-32-14.327-32-32V350z" fill="#515151" p-id="1283"></path><path d="M296.657 581.044a8 8 0 0 1 0-11.314l45.255-45.255a8 8 0 0 1 11.313 0l125.158 125.158 195.162-195.162a8 8 0 0 1 11.313 0l45.255 45.255a8 8 0 0 1 0 11.314L489.697 751.456c-6.248 6.249-16.379 6.249-22.627 0L296.657 581.044zM225 199a8 8 0 0 1 8-8h560a8 8 0 0 1 8 8v56a8 8 0 0 1-8 8H233a8 8 0 0 1-8-8v-56zM352 72a8 8 0 0 1 8-8h303a8 8 0 0 1 8 8v56a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8V72z" fill="#515151" p-id="1284"></path></svg>
)

class GoodList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            page: 1,
            CategoryData: "",
            searchValue: "",
            url: "",
            is_discount: false,
            is_coupon: false,
            is_open_recommend: false,
            is_authorization_code: false,

            shopList: "",  
            partner_id: "",
            LanguageData: "",
            language_id: 1, 


        };
        this.columns = [
            {
                title: "ID",
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },

             {
                title: "店铺",
                dataIndex:  ['partner','title'],
                width: '5%',
                align: "center",
            },


            {
                title: "主图",
                width: '5%',
                align: "center",
                render: (res) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={res.image} alt="" />
                    )
                }
            },
            {
                title: "名称",
                //dataIndex: 'name',
                width: '10%', 
                 render: (res) => {  
                    var isLock = "";
                    if(res.is_lock){
                        isLock =  <span style={{marginRight:'5px'}}><Icon type="lock" /></span> 
                    }


                    var isStrict = "";
                    if(res.is_strict){
                        isStrict =  <span style={{marginRight:'5px',color:'green'}}><Icon type="safety-certificate" /></span> 
                    }


                    var isStrictShow = "";
                    if(!res.is_strict_show && res.is_strict){
                        isStrictShow =  <span style={{marginRight:'5px',color:'red'}}>[审核中]</span> 
                    }else if(res.is_strict_show && res.is_strict){
                        isStrictShow =  <span style={{marginRight:'5px',color:'green'}}>[已审核]</span> ;
                    } 
                    return (<div> 
                        <span>{isStrictShow}{isStrict}{isLock}</span><span style={{ paddingRight:"5px"}}>{res.name}</span>
                     </div>)
                }

                // align: "center",
                // filters: [
                //     { text: '主食和粥', value: 60 },
                //     { text: '小吃零食', value: 63 },
                //     { text: '海鲜', value: 62 },
                //     { text: '空分类', value: 119 },
                //     { text: '素菜', value: 59 },
                //     { text: '荤菜', value: 61 },
                //     { text: '酒水饮料', value: 65 }
                // ],




            },
            {
                title: "分类",
                dataIndex: ["category", "name"],
                width: '10%',
                align: "center",

            },

            // {
            //     title: intl.get('goods.list.attribute'),
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {
            //         let value = ""
            //         let spuSku = record.spuSku.data.length
            //         let spuGoodsAnnexType = record.spuGoodsAnnexType.data.length
            //         if (spuSku > 0) {
            //             value = "sku"
            //         }

            //         if (spuGoodsAnnexType > 0) {
            //             value = "addl"
            //         }
            //         if (spuSku > 0 && spuGoodsAnnexType > 0) {
            //             value = "sku,addl"
            //         }
            //         return value
            //     }
            // },
            {
                title: "商品价格",
                dataIndex: 'show_price',
                width: '8%',
                align: "center",
                render: (record) => {
                    return <span>{currency_symbol}{record}</span>
                }
            },

            {
                title: "上架",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch disabled checked={record.state === 1} onChange={() => {
                            putGoods({ "state": record.state === 1 ? "0" : "1" }, record.id).then(res => {
                                this.GetgoodData(this.state.page)
                            })
                        }} />
                    )
                }
            },
            // {
            //     title: intl.get('goods.list.selected'),
            //     width: '10%',
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.recommend === 1} onChange={() => {
            //                 putGoods({ "recommend": record.recommend === 1 ? "0" : "1" }, record.id).then(res => {
            //                     this.GetgoodData(this.state.page)
            //                 }).catch(error => {
            //                     // console.log(error.response, "11")
            //                 })
            //             }} />
            //         )
            //     }
            // },
            {
                title: "显示",
                width: '5%',
                align: "center",
                render: (record) => {
                    let { is_authorization_code } = this.state
                    return (
                        <Switch disabled={!is_authorization_code} checked={record.is_show === 1} onChange={() => {
                            putGoods({ "is_show": record.is_show === 1 ? "0" : "1" }, record.id).then(res => {
                                this.GetgoodData(this.state.page)
                            }).catch(error => {
                                // console.log(error.response, "11")
                            })
                        }} />
                    )
                }
            }, {
                title: "锁定",
                width: '5%',
                align: "center",
                render: (record) => {
                     
                    return (
                        <Switch  checked={record.is_lock === 1} onChange={() => {
                            putGoods({ "is_lock": record.is_lock === 1 ? 0 : 1 }, record.id).then(res => {
                                this.GetgoodData(this.state.page,this.state.partner_id)
                            }).catch(error => {
                                // console.log(error.response, "11")
                            })
                        }} />
                    )
                }
            }, {
                title: "严选审核",
                width: '5%',
                align: "center",
                render: (record) => {
                     
                    return (
                        <Switch  checked={record.is_strict_show === 1} onChange={() => {
                            putGoods({ "is_strict_show": record.is_strict_show === 1 ? 0 : 1 }, record.id).then(res => {
                                this.GetgoodData(this.state.page)
                            }).catch(error => {
                                // console.log(error.response, "11")
                            })
                        }} />
                    )
                }
            },


            {
                title: "排序",
                dataIndex: 'sort',
                width: '5%',
                align: "center",
            },
            {
                title: "操作",
                dataIndex: 'operation',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return   <Tooltip title={"请到商家后台查看"}>
                            <Button
                                style={{ marginRight: "5px" }}
                                shape="circle"
                                icon="eye"
                                onClick={() => {
                                    
                                }} />
                        </Tooltip>
                    

                        {/*<Popover
                        style={{ width: 500 }}
                        content={
                            <div style={{ display: "flex" }}>
                                <Tooltip title={intl.get('seat.table.btn.edit')}>
                                    <Button
                                        style={{ marginRight: "5px" }}
                                        shape="circle"
                                        icon="edit"
                                        onClick={() => {
                                            this.props.history.push("/EditGood/" + record.id)
                                        }} />
                                </Tooltip>
                                 

                                
                                <Tooltip title={intl.get('nav.goodsManagement.promotionalgoods.coupon')}>
                                    <Button
                                        style={{ marginRight: "5px" }}
                                        shape="circle"
                                        icon="tags"
                                        onClick={() => {
                                            this.props.history.push("/couponList?spu_id=" + record.id)
                                        }} />
                                </Tooltip> 
                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover> */}

                    


                },
            },

        ]
    }

    async componentDidMount() {

        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({ LanguageData }, () => {
            this.HandleGetData(LanguageData[1].id)
        })




        getCategory().then(res => {
            let data = res.data.data;
            let list = [];
            for (let i in data) {
                list.push({
                    text: data[i].name,
                    value: data[i].id,
                })
            }
        })


        //this.GetgoodData(1)


         let resp = await getShopList("", "&page_size=999") 
        let data = resp.data.data
        console.log(data)

        if(data.length > 0 ){
            this.setState({
                shopList: data,
                partner_id: "",//默认选择全部店铺 data[0].id
            }, () => {
                //this.HandleGetData()
            })
        }else{
              this.setState({
                    shopList: [],
                    partner_id: ''
                }, () => {
                    //this.HandleGetData()
                })   
        }
        




    }




    async HandleGetData() {

       
       
        let { page, partner_id, url ,language_id} = this.state 
         this.GetgoodData(page,partner_id)


        /* 点击后查询数据
        let res = await getCouponList(page, partner_id, url || "")
        let shopData = await getShopDetail(partner_id, 1)
        this.setState({
            data: res.data.data,
            is_eatmoon_show: shopData.data.is_eatmoon_show === 1,
            meta: res.data.meta.pagination
        })
        */


    }





    async GetgoodData(num,partner_id  = "") {

         

        let res = await getGoodsList(num, this.state.url, this.state.searchValue, partner_id,this.state.language_id)
        let shopData = await getPartner()
        this.setState({
            is_discount: shopData.data.is_discount === 1,
            is_coupon: shopData.data.is_coupon === 1,
            is_open_recommend: shopData.data.is_open_recommend === 1,
            is_authorization_code: shopData.data.is_authorization_code === 1,
            is_open_goods_annex: shopData.data.is_open_goods_annex === 1,
            is_sku: shopData.data.is_sku === 1,
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    async handleDelete(data) {
        await deleteGoods(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }
    HandleOpenSelect = (data) => {
        UpdateShopMessage(data).then(res => {
            this.GetgoodData(1)
        })
    }


    render() {
        const { LanguageData,shopList, partner_id , data, meta, url, is_discount, is_coupon, is_open_goods_annex, is_sku, is_open_recommend, is_authorization_code } = this.state
        if (data) {
            return (
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                        <div>


                             {
                                LanguageData ? <Select
                                    style={{ width: "100px" }}
                                    defaultValue={LanguageData[1].name}
                                    onChange={(e) => { 
                                         this.setState({  language_id:e,page:1}, 
                                              () => {  this.HandleGetData(e)    } 
                                        )    
                                    }}
                                >
                                    {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select> : <div />
                            }



 

                             {
                            shopList &&
                                 <Select showSearch
                                    placeholder="请选择店铺"
                                    style={{ width: 300, marginLeft: "10px", marginRight: "10px" }}
                                    onChange={(e, value) => {
                                        this.setState({
                                            partner_id: e,
                                            url: "",
                                            page:1,
                                        }, () => this.HandleGetData())

                                    }}
                                     /*value={partner_id}*/ 
                                >
                                    {
                                        shopList.map(item => {
                                            return <Option value={item.id} key={item.id}>{item.title}</Option>
                                        })
                                    }
                                </Select>
                                }

                                <Tooltip title={'请选择店铺'}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>



                            {/*<Button
                                style={{ backgroundColor: url === "" ? "#5867dd" : "", color: url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: ""
                                    }, () => {
                                        this.GetgoodData(1)
                                    })
                                }}
                            >
                                {intl.get('home.table.useStatus.all')}
                            </Button>*/}
                             
                             
 

                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            {
                                is_authorization_code &&
                                <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                                    <span>{intl.get("goods.list.selected")}：</span>
                                    <Switch
                                        checked={is_open_recommend}
                                        onChange={() => {
                                            this.setState({
                                                is_open_recommend: !is_open_recommend
                                            }, () => {
                                                this.HandleOpenSelect({ "is_open_recommend": this.state.is_open_recommend ? "1" : "0" })
                                            })
                                        }} />
                                </div>
                            }

                            
                            {/*<Search
                                style={{ width: 250, marginRight: "10px" }}
                                placeholder={intl.get("goods.list.name.horder")}
                                enterButton={'search'}
                                onSearch={(val) => {
                                    this.setState({
                                        searchValue: val
                                    }, () => {
                                        this.GetgoodData(1)
                                    })
                                }}
                            />*/}
                            
                        </div>
                    </div>


                    <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", backgroundColor: 'none' , marginBottom: "10px"}}>


                        <Button
                        style={{ marginRight: "5px", backgroundColor: url === "" ? "#1DA57A" : "", color: url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                //partner_id: partner_id,
                                url: "", 
                                page:1,

                            }, () => {
                                this.HandleGetData(1, this.state.url)
                            })
                        }}>
                        全部
                    </Button> 

                    <Button
                        style={{ marginRight: "5px", backgroundColor: url === "&is_strict=1" ? "#1DA57A" : "", color: url === "&is_strict=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                //partner_id: partner_id,
                                page:1,
                                url: "&is_strict=1"  ,
                                //url_1:"&state=1",
                            }, () => {
                                this.HandleGetData(1, this.state.url)
                            })
                        }}>
                        严选商品
                    </Button> 
 
                 </div>





                    <Table
                        rowKey={record => record.id}
                        dataSource={data}
                        columns={this.columns}
                        // scroll={{ x: 1000 }}
                        footer={() => `${data.length}/${meta.total}`} 
                        pagination={{
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(a, b, c) => {
                            this.setState({
                                page: a.current
                            }, () => this.GetgoodData(a.current,this.state.partner_id))
                        }}
                        className="waperBox"
                    />
                </div>

            );
        } else {
            return (<div />)
        }

    }
}

export default withRouter(Form.create()(GoodList))

