import React from "react";
import { withRouter } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import { Input, InputNumber, Table, Form, Switch, Button, Select, Modal, Card, Popover, message ,Icon} from 'antd';
import { getShopList, putShop } from "../../action/shopAction";
import { getLanguageList, GetConfigSet } from "../../action/authAction"
import "./index.css"
const { Search, TextArea } = Input;

class ShopList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            page: 1,
            LanguageData: "",
            language_id: "",
            visible: false,
            passwordVisible: false,
            newPassword: "",
            confirmPassword: "",
            isError: false,
            ItemId: "",
            is_open_spu_many_help: false,
            is_disable_goods_annex: false,
            is_open_recommend: false,
            is_open_moon_enable: false,
            is_open_partner_seat: false,
            is_eatmoon_show: false,
            is_open_deal_eatmoon_show:false,
            is_open_in_store:false,
            is_open_on_delivery: false,
            is_open_on_delivery_goods: false,
            is_open_on_delivery_sys: false,
            is_open_logistics: false,
            is_authorization_code: false,
            is_open_printer: false,
            is_open_order_receiving: false,
            is_discount: false,
            is_update_activity_service_rate: false,
            is_coupon: false,
            is_open_up_live: false,
            is_open_sku: false,
            is_system_delivery_free: false,
            is_open_gst: false,
            is_open_full_delivery_free: false,
            is_open_spu_many_help_system: false,
            is_self_pay: false,
            up_live_udid: "",
            up_live_udid_null: false,
            up_live_token: "",
            up_live_token_null: false,
            up_live_visible: false,
            confirmPasswordError: false,
            chooseAll: true,
            auditVisible: false,
            auditValue: "",
            packageVisible: false,
            ItemPackageData: "",
            ItemData: "",
            activity_order_fixed_fee: "",
            order_rate: "",
            activity_service_rate: "",

            is_open_member_level:false, //系统会员等级
            is_open_member_group_up_value:false,//系统会员成长值
            is_open_user_points:false,//系统用户积分
            is_open_strict:false,

        };
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                align: "center"
            },
            // {
            //     title: 'bdid',
            //     dataIndex: 'bdid',
            //     width: '5%',
            //     align: "center"
            // },
            {
                title: '商家logo',
                width: '5%',
                align: "center",
                render: (res) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover', backgroundColor: "#fff", borderRadius: "10px" }} src={res.logo} alt="" />
                    )
                }
            },
            {
                title: '用户名',
                dataIndex: 'name',
                width: '8%',
                align: "center"
            }, {
                title: '店铺名称',
                //dataIndex: 'title',
                width: '8%',
                align: "center",
                 render: (res) => {
                    return (
                        <span> {res.is_audit ? "" : <Icon type="shop"  style={{ marginRight: "5px",  }} /> }  { res.title  }   </span>
                    )
                }


            },
            // {
            //     title: '商家手续费',
            //     dataIndex: 'charge_rate',
            //     width: '7%',
            //     align: "center"
            // },
            // {
            //     title: '满额配送',
            //     dataIndex: 'full_delivery_free',
            //     width: '7%',
            //     align: "center"
            // },
            // {
            //     title: '商家电话',
            //     width: '10%',
            //     align: "center",
            //     render: (res) => {
            //         return (
            //             <div><span>{res.phone}{res.reserve_phone && "/"}{res.reserve_phone && res.reserve_phone}</span></div>
            //         )
            //     }
            // },
            {
                title: '套餐',
                dataIndex: 'package[name]',
                width: '6%',
                align: "center"
            },
            // {
            //     title: '商家邮件',
            //     dataIndex: 'email',
            //     width: '10%',
            //     align: "center"
            // },
            // {
            //     title: '商家地址',
            //     dataIndex: 'partnerAddress[fill]',
            //     width: '10%',
            //     align: "center"
            // },
            {
                title: '店铺状态',
                width: '8%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.status === 1} onChange={() => this.onChange(record)} />
                    )
                }
            },
            {
                title: '行为',
                width: '5%',
                align: "center",
                render: (res) => {
                    return (
                         <div style={{display:"flex",    flexDirection: 'column' }}>
                        <span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                this.setState({
                                    ItemId: res.id,
                                    is_open_spu_many_help: res.is_open_spu_many_help === 1,
                                    is_disable_goods_annex: res.is_disable_goods_annex === 1,
                                    is_open_moon_enable: res.is_open_moon_enable === 1,
                                    is_open_recommend: res.is_open_recommend === 1,
                                    is_open_partner_seat: res.is_open_partner_seat === 1,
                                    is_eatmoon_show: res.is_eatmoon_show === 1,
                                    is_open_deal_eatmoon_show: res.is_open_deal_eatmoon_show === 1,
                                    is_open_in_store: res.is_open_in_store === 1,
                                    is_open_on_delivery: res.is_open_on_delivery === 1,
                                    is_open_on_delivery_goods: res.is_open_on_delivery_goods === 1,
                                    is_open_on_delivery_sys: res.is_open_on_delivery_sys === 1,
                                    is_open_logistics: res.is_open_logistics === 1,
                                    is_authorization_code: res.is_authorization_code === 1,
                                    is_open_printer: res.is_open_printer === 1,
                                    is_open_order_receiving: res.is_open_order_receiving === 1,
                                    is_discount: res.is_discount === 1,
                                    is_update_activity_service_rate: res.is_update_activity_service_rate === 1,
                                    is_coupon: res.is_coupon === 1,
                                    is_open_up_live: res.is_open_up_live === 1,
                                    is_open_sku: res.is_open_sku === 1,
                                    is_system_delivery_free: res.is_system_delivery_free === 1,
                                    is_open_gst: res.is_open_gst === 1,
                                    is_open_full_delivery_free: res.is_open_full_delivery_free === 1,
                                    is_self_pay: res.is_self_pay === 1,
                                    is_open_member_level: res.is_open_member_level === 1,
                                    is_open_member_group_up_value: res.is_open_member_group_up_value === 1,
                                    is_open_strict: res.is_open_strict === 1,
                                    is_open_user_points: res.is_open_user_points === 1,
                                    up_live_token: res.up_live_token,
                                    up_live_udid: res.up_live_udid,
                                    visible: true
                                })
                            }}
                        >详情</span>



                        {/*<span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                 this.props.history.push("/Shopbehavior/" + res.id)
                            }}
                        >全部
                        </span>*/}

                        </div>


                    )
                }
            },
            {
                title: '套餐',
                width: '5%',
                align: "center",
                render: (res) => {
                    return (
                        <span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                this.setState({
                                    packageVisible: true,
                                    ItemPackageData: res.package,
                                    itemId: res.id,
                                    ItemData: res
                                })
                            }}
                        >详情</span>
                    )
                }
            },
            {
                title: '套餐日志',
                width: '6%',
                align: "center",
                render: (res) => {
                    return (
                        <span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                this.props.history.push("/PackageLogList/" + res.id)
                            }}
                        >详情</span>
                    )
                }
            },
            {
                title: '费用',
                width: '6%',
                align: "center",
                render: (res) => {
                    return (
                        <div style={{display:"flex",    flexDirection: 'column' }}>
                        <span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                this.props.history.push("/orderChargeFeeList/" + res.id)
                            }}
                        >订单服务费</span>
                        <span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                this.props.history.push("/orderFeeList/" + res.id)
                            }}
                        >平台订单</span>

                        <span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                this.props.history.push("/AnnualFeeList/" + res.id)
                            }}
                        >其他</span>

                        <span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                this.props.history.push("/statementList/" + res.id)
                            }}
                        >结算清单</span>


                        </div>
                    )
                }
            },
            {
                title: '支付设置',
                width: '6%',
                align: "center",
                render: (res) => {
                    return (
                        <span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                this.props.history.push("/StripeSet/" + res.id)
                            }}
                        >详情</span>
                    )
                }
            },

            {
                title: '打印机',
                width: '6%',
                align: "center",
                render: (res) => {
                    return (
                        <span style={{ color: "#1DA57A", cursor: "pointer" }}
                            onClick={() => {
                                this.props.history.push("/PrinterList/" + res.id)
                            }}
                        >详情</span>
                    )
                }
            },

            {
                title: '操作',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <Popover
                        style={{ width: 500 }}
                        content={
                            <div style={{ display: "flex" }}>
                                <Button
                                    style={{ marginRight: "5px" }}
                                    onClick={() => this.showPasswordModal(record)}>
                                    修改密码
                                </Button>
                                <Button
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {
                                        this.props.history.push("/editshop/" + record.id)
                                    }}>编辑</Button>
                                {
                                    record.is_audit === 0 &&
                                    <Button onClick={() => {
                                        this.setState({
                                            auditVisible: true,
                                            ItemId: record.id
                                        })

                                    }}>审核</Button>
                                }
                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover>
                },
            },

        ];

    }


    isEditing = record => record.id === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };


    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({ LanguageData, language_id: LanguageData[1].id }, () => {
            this.GetShopData(1, "", LanguageData[1].id)
        })

    }

    async GetShopData(num, url, language_id) {
        let data = await getShopList(num, url, language_id)
        let res = await GetConfigSet()
        let allData = res.data.data
        this.setState({
            data: data.data.data,
            meta: data.data.meta.pagination,
            language_id,
            is_open_spu_many_help_system: allData.find(el => el.key === "is_open_spu_many_help").value == 1
        })
    }



    async onChange(record) {
        let { data } = this.state
        let NewData = data
        let status = "0"
        if (record.status === 1) {
            status = "0"
        } else {
            status = 1
        }
        for (let i in NewData) {
            if (NewData[i].id === record.id) {
                NewData[i].status = status
                this.setState({
                    data: NewData
                })
                break
            }
        }
        await putShop(record.id, { status })
    }


    showPasswordModal = (item) => {
        this.setState({
            passwordVisible: true,
            itemId: item.id,
            newPassword: "",
            confirmPassword: "",
            newPasswordError: false,
            confirmPasswordError: false,
        });
    };

    handlePasswordOk = e => {
        const { newPassword, confirmPassword, itemId, newPasswordError, confirmPasswordError } = this.state
        if (newPassword !== confirmPassword) {
            this.setState({
                isError: true
            })
        } else {
            this.setState({
                isError: false
            })
            if (newPassword && confirmPassword && !newPasswordError && !confirmPasswordError) {
                putShop(itemId, { "password": newPassword }).then(res => {
                    this.setState({
                        passwordVisible: false,
                    });
                })
            }
        }
    };

    handlePackageFreeOk = async () => {
        let { activity_order_fixed_fee, order_rate, activity_service_rate, itemId } = this.state
        let values = {}

        values["activity_order_fixed_fee"] = activity_order_fixed_fee
        values["rate"] = order_rate ? parseInt(order_rate) / 100 : ""
        values["activity_service_rate"] = activity_service_rate ? parseInt(activity_service_rate) / 100 : ""
        await putShop(itemId, values)

    }

    handleCancel = e => {
        this.setState({
            visible: false,
            passwordVisible: false,
        });
    };


    render() {
        const {is_open_strict, is_open_member_level, is_open_member_group_up_value,is_open_user_points,data, meta, ItemData, LanguageData, is_open_spu_many_help,is_open_moon_enable, is_disable_goods_annex, is_open_recommend,is_open_partner_seat, is_eatmoon_show, is_open_deal_eatmoon_show,   is_open_in_store, is_open_on_delivery,is_open_on_delivery_goods, is_open_on_delivery_sys,is_open_logistics, is_authorization_code, is_open_printer, is_open_order_receiving, is_discount, is_update_activity_service_rate, is_coupon, is_open_up_live, is_open_sku, is_system_delivery_free, is_open_gst,is_open_full_delivery_free, language_id, ItemId, is_open_spu_many_help_system, newPassword, confirmPassword, is_self_pay, up_live_token, up_live_token_null, up_live_udid, auditVisible, auditValue, chooseAll, ItemPackageData } = this.state
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        if (data) {
            return (
                <div>
                    <div style={{ width: "100%", height: "80px", display: "flex", justifyContent: "space-between", alignItems: "center",   }}>
                        <div>
                            {
                                LanguageData ? <Select
                                    style={{ width: "100px", margin: "0px 10px" }}
                                    defaultValue={LanguageData[1].name}
                                    onChange={(e) => {
                                        this.GetShopData(1, "", e)
                                    }}
                                >
                                    {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select> : <div />

                            }
                            <Search
                                style={{ width: 400, marginLeft: "5px" }}
                                placeholder={'请输入店铺名称'}
                                enterButton={'查询'}
                                onSearch={(val) => {
                                    this.GetShopData(1, "&search=" + val, this.state.language_id)
                                }}
                            />
                            <Button
                                onClick={() => {
                                    this.setState({
                                        chooseAll: true
                                    }, () => this.GetShopData(1, "", this.state.language_id))
                                }}
                                style={{ marginRight: "10px", marginLeft: '10px', backgroundColor: chooseAll ? "#1DA57A" : "#fff", color: chooseAll ? "#fff" : "#000000a6" }}
                            >
                                全部
                            </Button>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        chooseAll: false
                                    }, () => this.GetShopData(1, "&is_audit=0", this.state.language_id))
                                }}
                                style={{ backgroundColor: chooseAll ? "#fff" : "#1DA57A", color: chooseAll ? "#000000a6" : "#fff" }}
                            >
                                未审核商家
                            </Button>
                        </div>



                        <Button type="primary" onClick={() => this.props.history.push("/newShop")} style={{ marginRight: "30px", marginLeft: '30px' }}>
                            <PlusOutlined /> 新建
                        </Button>
                    </div>

                    <div  className="waperBox">
                    <Table
                        rowKey='id'
                        dataSource={data}
                        columns={this.columns}
                        pagination={{
                            hideOnSinglePage: true,
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(e) => {
                            this.setState({
                                page: e.current
                            }, () => this.GetShopData(e.current, ""))

                        }}
                    />
                    </div>

                    
                    <Modal
                        title="修改密码"
                        visible={this.state.passwordVisible}
                        onOk={this.handlePasswordOk}
                        onCancel={this.handleCancel}
                    >
                        <div style={{ height: "60px" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>密码:</span>
                                <Input
                                    value={newPassword}
                                    onChange={(e) => {
                                        e.persist()
                                        //let value = e.target.value.replace(/\W/g, '') 
                                        let value = e.target.value;



                                        //密码正则校验
                                        //var patt=/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/g;
                                        var patt = /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d`~!@#$%^&*()_+<>?:"{},.\/;\\'[\]=\-]{6,32}$/g;
                                        
                                        var result=patt.exec(value);    
                                        if(result == null){  
                                            this.setState({
                                                newPasswordError: true
                                            }) 
                                        }else {
                                            if (value.length > 32 || value.length < 6) {
                                                this.setState({
                                                    newPasswordError: true
                                                })

                                            } else {
                                                this.setState({
                                                    newPasswordError: false
                                                })
                                            }   
                                        }
 
                                        
                                        this.setState({
                                            newPassword: value
                                        })
                                    }} />
                            </div>
                            {
                                this.state.newPasswordError &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请输入6-32位数字和字母，允许特殊符号</span>
                                </div>
                            }
                        </div>
                        <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>确认密码:</span>
                                <Input
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        e.persist()
                                        //let value = e.target.value.replace(/\W/g, '')
                                        let value = e.target.value


                                        //密码正则校验
                                        //var patt=/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/g;

                                        var patt = /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d`~!@#$%^&*()_+<>?:"{},.\/;\\'[\]=\-]{6,32}$/g;
                                        var result=patt.exec(value);    
                                        if(result == null){
                                            this.setState({
                                                confirmPasswordError: true
                                            })
                                        }else{
                                            if (value.length > 32 || value.length < 6) {
                                                this.setState({
                                                    confirmPasswordError: true
                                                })
                                            } else {
                                                this.setState({
                                                    confirmPasswordError: false
                                                })
                                            }
                                        } 
                                        
 
                                        this.setState({
                                            confirmPassword: value
                                        })
                                    }} />
                            </div>
                            {
                                this.state.isError &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>两次密码不一致</span>
                                </div>
                            }
                            {
                                this.state.confirmPasswordError &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请输入6-32位数字和字母，允许特殊符号</span>
                                </div>
                            }

                        </div>
                    </Modal>
                    <Modal
                        title="套餐详情"
                        visible={this.state.packageVisible}
                        onOk={this.handlePackageFreeOk}
                        onCancel={() => {
                            this.setState({
                                packageVisible: false
                            })
                        }}
                    >
                        <Form {...formItemLayout}>

                            {
                                String(ItemPackageData.name).length > 0 &&

                                <Form.Item
                                    label="套餐名称"
                                >
                                    <Input disabled value={ItemPackageData.name} style={{ width: "200px" }} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.setup_fee).length > 0 &&
                                <Form.Item
                                    label="初始费"
                                >
                                    <Input disabled prefix="$" value={ItemPackageData.setup_fee} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.year_service_fee).length > 0 &&
                                <Form.Item
                                    label="年达标费"
                                >
                                    <Input disabled prefix="$" value={ItemPackageData.year_service_fee} />
                                </Form.Item>
                            }


                            {
                                String(ItemPackageData.month_service_fee).length > 0 &&
                                <Form.Item
                                    label="月达标费"
                                >
                                    <Input disabled prefix="$" value={ItemPackageData.month_service_fee} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.month_service_fee_by_order_num).length > 0 &&
                                <Form.Item
                                    label="月达标单数"
                                >
                                    <Input disabled value={ItemPackageData.month_service_fee_by_order_num} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.money_standard_activity_order_num).length > 0 &&
                                <Form.Item
                                    label="月活动达标单数"
                                >
                                    <Input disabled value={ItemPackageData.money_standard_activity_order_num} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.contract_term_standard_order_num).length > 0 &&
                                <Form.Item
                                    label="签约期达标单数"
                                >
                                    <Input disabled value={ItemPackageData.contract_term_standard_order_num} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.contract_term_standard_activity_order_num).length > 0 &&
                                <Form.Item
                                    label="签约期活动达标单数"
                                >
                                    <Input disabled value={ItemPackageData.contract_term_standard_activity_order_num} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.contract_date).length > 0 &&
                                <Form.Item
                                    label="签约期（月）"
                                >
                                    <Input disabled value={ItemPackageData.contract_date} />
                                </Form.Item>
                            }

                            {
                                String(ItemPackageData.order_fixed_fee).length > 0 &&
                                <Form.Item
                                    label="定额订单服务费"
                                >
                                    <Input disabled prefix="$" value={ItemPackageData.order_fixed_fee} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.rate).length > 0 &&
                                <Form.Item
                                    label="商品订单服务费"
                                >
                                    <Input disabled min={0} style={{ width: '100%' }} suffix="%" value={ItemPackageData.rate} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.activity_service_rate).length > 0 &&
                                <Form.Item
                                    label="活动订单服务费"
                                >
                                    <Input disabled min={0} style={{ width: '100%' }} suffix="%" value={ItemPackageData.activity_service_rate} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.trial_period_days).length > 0 &&
                                <Form.Item
                                    label="体验期（月）"
                                >
                                    <InputNumber min={0} style={{ width: '100%' }} value={ItemPackageData.trial_period_days} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.fee_reduction_fixed_fee).length > 0 &&
                                <Form.Item
                                    label="体验期定额订单服务费"
                                >
                                    <Input disabled min={0} style={{ width: '100%' }} prefix="$" value={ItemPackageData.fee_reduction_fixed_fee} />
                                </Form.Item>
                            }

                            {
                                String(ItemPackageData.fee_reduction_rate).length > 0 &&
                                <Form.Item
                                    label="体验期商品订单服务费"
                                >
                                    <Input disabled min={0} style={{ width: '100%' }} suffix="%" value={ItemPackageData.fee_reduction_rate} />
                                </Form.Item>
                            }

                            {
                                String(ItemPackageData.fee_reduction_activity_service_rate).length > 0 &&
                                <Form.Item
                                    label="体验期活动订单服务费"
                                >
                                    <Input disabled min={0} style={{ width: '100%' }} suffix="%" value={ItemPackageData.fee_reduction_activity_service_rate} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.prestore_money).length > 0 &&
                                <Form.Item
                                    label="预存金额"
                                >
                                    <Input disabled min={0} style={{ width: '100%' }} prefix="$" value={ItemPackageData.prestore_money} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.payment_period).length > 0 &&
                                <Form.Item
                                    label="结算周期"
                                >
                                    <Input disabled min={0} style={{ width: '100%' }} value={ItemPackageData.payment_period} />
                                </Form.Item>
                            }

                            {
                                String(ItemPackageData.sort).length > 0 &&
                                <Form.Item
                                    label="排序"
                                >
                                    <InputNumber disabled min={0} style={{ width: '100%' }} value={ItemPackageData.sort} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.spu_quantity).length > 0 &&
                                <Form.Item
                                    label="商品数量"
                                >
                                    <InputNumber disabled style={{ width: '100%' }} value={ItemPackageData.spu_quantity} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.coupon_quantity).length > 0 &&
                                <Form.Item
                                    label="优惠券ticket数量"
                                >
                                    <InputNumber disabled style={{ width: '100%' }} value={ItemPackageData.coupon_quantity} />
                                </Form.Item>
                            }
                            {
                                String(ItemPackageData.remark).length > 0 &&
                                <Form.Item
                                    label="简介"
                                >
                                    <TextArea disabled value={ItemPackageData.remark} />
                                </Form.Item>
                            }
                            {/* {
                                ItemPackageData.is_show === 1 &&
                                <Form.Item
                                    label="是否显示"
                                >
                                    <Switch disabled checked={ItemPackageData.is_show === 1} />
                                </Form.Item>
                            }
                            {
                                ItemPackageData.is_authorization_code === 1 &&
                                <Form.Item
                                    label="开启授权"
                                >
                                    <Switch disabled checked={ItemPackageData.is_authorization_code === 1} />
                                </Form.Item>
                            }
                            {
                                ItemPackageData.is_own_payment === 1 &&
                                <Form.Item
                                    label="支持独立结算"
                                >
                                    <Switch disabled checked={ItemPackageData.is_own_payment === 1} />
                                </Form.Item>
                            }
                            {
                                ItemPackageData.is_langs === 1 &&
                                <Form.Item
                                    label="支持商家多语言"
                                >
                                    <Switch disabled checked={ItemPackageData.is_langs === 1} />
                                </Form.Item>
                            }

                            {
                                ItemPackageData.is_have_h5 === 1 &&
                                <Form.Item
                                    label="支持H5移动端浏览器"
                                >
                                    <Switch checked={ItemPackageData.is_have_h5 === 1} disabled />
                                </Form.Item>
                            }
                            {
                                ItemPackageData.is_have_domain === 1 &&
                                <Form.Item
                                    label="支持独立域名"
                                >
                                    <Switch checked={ItemPackageData.is_have_domain === 1} disabled />
                                </Form.Item>
                            }
                            {
                                ItemPackageData.is_scan_code === 1 &&
                                <Form.Item
                                    label="支持立牌"
                                >
                                    <Switch checked={ItemPackageData.is_scan_code === 1} disabled />
                                </Form.Item>
                            } */}

                            <Form.Item
                                label="商家订单定额服务费"
                            >
                                <Input
                                    prefix="$"
                                    min={0}
                                    style={{ width: '100%' }}
                                    defaultValue={ItemData?.activity_order_fixed_fee}
                                    onChange={(e) => {
                                        e.persist()
                                        this.setState({
                                            activity_order_fixed_fee: e.target.value
                                        })
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="商家商品订单服务费"
                            >
                                <Input
                                    min={0}
                                    style={{ width: '100%' }}
                                    suffix="%"
                                    defaultValue={ItemData.rate ? parseFloat(ItemData.rate) * 100 : ""}
                                    onChange={(e) => {
                                        e.persist()
                                        this.setState({
                                            order_rate: e.target.value
                                        })
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="商家活动订单服务费"
                            >
                                <Input
                                    min={0}
                                    style={{ width: '100%' }}
                                    suffix="%"
                                    defaultValue={ItemData.activity_service_rate ? parseFloat(ItemData.activity_service_rate) * 100 : ""}
                                    onChange={(e) => {
                                        e.persist()
                                        this.setState({
                                            activity_service_rate: e.target.value
                                        })
                                    }}
                                />
                            </Form.Item>

                        </Form>
                    </Modal>



                    <Modal
                        title="行为"
                        visible={this.state.visible} 
                        width={"90%"}
                        onCancel={() => {
                            this.setState({
                                visible: false,
                                ItemId: "",
                                is_open_spu_many_help: false,
                                is_disable_goods_annex: false,
                                is_open_moon_enable: false,
                                is_open_recommend:false,
                                is_open_partner_seat: false,
                                is_eatmoon_show: false,
                                is_open_deal_eatmoon_show: false,
                                is_open_in_store:false,
                                is_open_on_delivery: false,
                                is_open_on_delivery_goods: false,
                                is_open_on_delivery_sys: false,
                                is_open_logistics: false,
                                is_authorization_code: false,
                                is_open_printer: false,
                                is_open_order_receiving: false,
                                is_discount: false,
                                is_update_activity_service_rate: false,
                                is_coupon: false,
                                is_open_up_live: false,
                                is_open_sku: false,
                                is_system_delivery_free: false,
                                is_open_gst:false,
                                is_open_full_delivery_free: false,
                            })
                        }}
                        footer={null}
                    >

                        <div style={{ display:"flex",flexDirection:"row", justifyContent: "space-between",overflow:"auto" }}  >







                        <Card style={{ marginBottom: "10px" }}>

                            <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v0 核心
                                </div>

                            </div>


                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px" }}>
                                    功能
                                </div>
                                <div>
                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启授权码：</span>
                                        <Switch
                                             
                                            checked={is_authorization_code}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_authorization_code: e
                                                })
                                                if (is_authorization_code) {
                                                    this.setState({
                                                        is_open_on_delivery: false,
                                                        is_open_logistics: false,
                                                        is_system_delivery_free: false,
                                                        is_open_full_delivery_free: false,
                                                        is_discount: false,
                                                    })
                                                }
                                                putShop(ItemId, { is_authorization_code: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>


                              
                                         <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启打印机：</span>
                                        <Switch checked={is_open_printer}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_printer: e
                                                })
                                                putShop(ItemId, { is_open_printer: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                     <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启接单：</span>
                                        <Switch checked={is_open_order_receiving}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_order_receiving: e
                                                })
                                                putShop(ItemId, { is_open_order_receiving: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>



                                  

                                      <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启GST：</span>
                                        <Switch
                                            checked={is_open_gst} 
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_gst: e
                                                })
                                                putShop(ItemId, { is_open_gst: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>



                                    





                                </div>
                            </div>


                           <div style={{ display: "flex",borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}>

                                <div style={{ width: "100px" }}>
                                    免费插件
                                </div>
                                <div> 
                                     
                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启sku：</span>
                                        <Switch checked={is_open_sku}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_sku: e
                                                })
                                                putShop(ItemId, { is_open_sku: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>


                                     <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启精选商品：</span>
                                        <Switch checked={is_open_recommend}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_recommend: e
                                                })
                                                putShop(ItemId, { is_open_recommend: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div> 
                                </div>
                            


                                <div style={{ width: "100px" }}>
                                    收费插件
                                </div>
                                <div> 
                                      
                                     <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启附加商品：</span>
                                        <Switch checked={is_disable_goods_annex}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_disable_goods_annex: e
                                                })
                                                putShop(ItemId, { is_disable_goods_annex: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>
                                </div>
                             





                                </div>


                        </Card>















                        <Card style={{ marginBottom: "10px" }}>

                            <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v1 到店
                                </div>

                            </div>


                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px" }}>
                                    功能
                                </div>

                                <div>
                                    
 

                                    <div style={{ display: "flex", alignItems: "center" , marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启到店：</span>
                                        <Switch checked={is_open_in_store}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_in_store: e
                                                })
                                                putShop(ItemId, { is_open_in_store: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>
 

                                </div>
                            </div>


                            <div style={{ display: "flex",borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}>

                                <div style={{ width: "100px" }}>
                                    收费插件
                                </div>
                                <div>
                                     
 
                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启桌号：</span>
                                        <Switch checked={is_open_partner_seat}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_partner_seat: e
                                                })
                                                putShop(ItemId, { is_open_partner_seat: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                    

                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启招牌菜：</span>
                                        <Switch checked={is_open_partner_seat}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_partner_seat: e
                                                })
                                                putShop(ItemId, { is_open_partner_seat: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div> 
                                </div>


                                <div style={{ width: "100px",display:"none" }}>
                                    收费插件
                                </div>
                                <div> 
                                      
                                     
                                </div>





                            </div>





                        </Card>






                        <Card style={{ marginBottom: "10px" }}>

                         <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v2 配送
                                </div>

                            </div>



                            <div style={{ display: "flex",  flexDirection: "column" }}>
                                <div style={{ width: "100px"  }}>
                                    功能
                                </div>
                                <div>
                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启配送：</span>
                                        <Switch checked={is_open_on_delivery}
                                            disabled={!is_authorization_code}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_on_delivery: e,

                                                    is_open_logistics: true,
                                                    is_system_delivery_free: true,
                                                    is_open_on_delivery_goods:true,


                                                })
                                                if (is_open_on_delivery) {
                                                    this.setState({
                                                        is_open_logistics: false,
                                                        is_system_delivery_free: false,
                                                        is_open_on_delivery_goods:false,
                                                    })
                                                }
                                                putShop(ItemId, { is_open_on_delivery: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>


                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px"  }}>配送模式</span>
                                         
                                    </div>



                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启送货：</span>
                                        <Switch
                                            checked={is_open_on_delivery_goods}
                                            disabled={!is_open_on_delivery}
                                            disabled
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_on_delivery_goods: e,
                                                })
                                                if (is_open_on_delivery_goods) {
                                                    this.setState({
                                                        is_system_delivery_free: false,
                                                        is_open_logistics:false,
                                                    })
                                                }
                                                putShop(ItemId, { is_open_on_delivery_goods: e ? "1" : "0",is_open_logistics:0 }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>






                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启商家自行送货：</span>
                                        <Switch
                                            checked={is_open_logistics}
                                            disabled = {!is_open_on_delivery || !is_open_on_delivery_goods }
                                            disabled
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_logistics: e,
                                                })
                                                if (is_open_logistics) {
                                                    this.setState({
                                                        is_system_delivery_free: false,
                                                    })
                                                }
                                                putShop(ItemId, { is_open_logistics: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启系统送货：</span>
                                        <Switch
                                            checked={is_open_on_delivery_sys}
                                            disabled 
                                            onChange={(e) => { 
                                            }} />
                                    </div>




                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px", marginTop: "30px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启商家运费：</span>
                                        <Switch
                                            checked={is_system_delivery_free}
                                            disabled={!is_open_logistics || !is_open_on_delivery || !is_self_pay}
                                            disabled
                                            onChange={(e) => {
                                                this.setState({
                                                    is_system_delivery_free: e
                                                })
                                                putShop(ItemId, { is_system_delivery_free: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px"  }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启系统运费：</span>
                                        <Switch
                                            checked= {false}
                                            disabled = {true}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_system_delivery_free: e
                                                })
                                                putShop(ItemId, { is_system_delivery_free: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>





                                   





                                    

                                </div>
                            </div>





                            <div style={{ display: "flex",borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}> 
                                <div style={{ width: "100px" }}>
                                    免费插件
                                </div>
                                <div>
                                     

                                   <div style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启满包邮：</span>
                                        <Switch
                                            checked={is_open_full_delivery_free}
                                            disabled={!is_system_delivery_free}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_full_delivery_free: e
                                                })
                                                putShop(ItemId, { is_open_full_delivery_free: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>
 
                                </div>


                                <div style={{ width: "100px" }}>
                                    收费插件
                                </div>
                                <div>
                                     

                                   <div style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>打包费：</span>
                                        <Switch
                                            checked={is_open_full_delivery_free}
                                            disabled={!is_system_delivery_free}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_full_delivery_free: e
                                                })
                                                putShop(ItemId, { is_open_full_delivery_free: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>
 
                                </div>






                            </div>




                        </Card>


                         


                        <Card style={{ marginBottom: "10px" }}>


                         <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v3 Deal
                                </div>

                            </div>



                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px" }}>
                                    功能
                                </div>
                                <div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启Deal：</span>
                                        <Switch
                                            checked={is_discount}
                                            disabled={!is_authorization_code}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_discount: e
                                                })
                                                putShop(ItemId, { is_discount: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>



                                    <div style={{ display: "flex", alignItems: "center" , marginTop: "15px"  }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启平台显示：</span>
                                        <Switch checked={is_open_deal_eatmoon_show}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_deal_eatmoon_show: e
                                                })
                                                putShop(ItemId, { is_open_deal_eatmoon_show: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>




                                    {/*
                                    <div style={{ display: "flex", alignItems: "center", marginTop: "15px", }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>是否修改服务费：</span>
                                        <Switch
                                            checked={is_update_activity_service_rate}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_update_activity_service_rate: e
                                                })
                                                putShop(ItemId, { is_update_activity_service_rate: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>
                                    */}



                                </div>
                            </div>


                            <div style={{ borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}> 
                                <div style={{ width: "100px" }}>
                                    收费插件
                                </div>
                                <div>
                                     

                                    {
                                        is_open_spu_many_help_system &&
                                        <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                            <span style={{ width: "150px", textAlign: "right" }}>开启砍一刀：</span>
                                            <Switch checked={is_open_spu_many_help}
                                                onChange={(e) => {
                                                    this.setState({
                                                        is_open_spu_many_help: e
                                                    })
                                                    putShop(ItemId, { is_open_spu_many_help: e ? "1" : "0" }).then(res => {
                                                        this.GetShopData(1, "", language_id)
                                                    })
                                                }} />
                                        </div>
                                    }


                                    
 
                                </div>
                            </div>



                        </Card>


                        <Card style={{ marginBottom: "10px" }}>


                         <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v4 优惠券
                                </div>

                            </div>



                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px" }}>
                                    功能
                                </div>
                                <div>

                                    <div style={{ display: "flex", alignItems: "center",   }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启优惠券：</span>
                                        <Switch checked={is_coupon}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_coupon: e
                                                })
                                                putShop(ItemId, { is_coupon: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>

                                    

                                     <div style={{ display: "flex", alignItems: "center" , marginTop: "15px"  }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启平台显示：</span>
                                        <Switch checked={is_eatmoon_show}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_eatmoon_show: e
                                                })
                                                putShop(ItemId, { is_eatmoon_show: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>





                                    <div style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启小月亮：</span>
                                        <Switch checked={is_open_moon_enable}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_moon_enable: e
                                                })
                                                putShop(ItemId, { is_open_moon_enable: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>



     






                                </div>
                            </div>


                            <div style={{ display: "none",borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}> 
                                <div style={{ width: "100px" }}>
                                    免费插件
                                </div>
                                <div>
                                     

                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>Demo：</span>
                                        <Switch checked={is_disable_goods_annex}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_disable_goods_annex: e
                                                })
                                                putShop(ItemId, { is_disable_goods_annex: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div> 
 
                                </div>
                            </div>



                        </Card>




                        <Card style={{ marginBottom: "10px" }}>


                         <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v5 会员
                                </div>

                            </div>



                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px" }}>
                                    功能
                                </div>
                                <div>



                                <div style={{ display: "flex", alignItems: "center",   marginTop: "15px"  }}>
                                    <span style={{ width: "150px", textAlign: "right" }}>成长值：</span>
                                    <Switch   disabled  checked={is_open_member_group_up_value}
                                        onChange={(e) => {
                                            this.setState({
                                                is_open_member_group_up_value: e
                                            })
                                            putShop(ItemId, { is_open_member_group_up_value: e ? "1" : "0" }).then(res => {
                                                this.GetShopData(1, "", language_id)
                                            })
                                        }} />
                                </div>

 

                                    <div style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>积分：</span>
                                        <Switch disabled checked={is_open_user_points}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_user_points: e
                                                })
                                                putShop(ItemId, { is_open_user_points: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>



     






                                </div>
                            </div>



                            <div style={{  borderTop:"1px solid #dedede",marginTop:'20px',paddingTop:'20px',  flexDirection: "column"  }}> 
                                <div style={{ width: "100px" }}>
                                    免费插件
                                </div>
                                <div> 
                                  
                                      <div style={{ display: "flex", alignItems: "center" , marginTop: "15px"  }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>会员等级：</span>
                                        <Switch disabled checked={is_open_member_level}
                                            onChange={(e) => {
                                                this.setState({
                                                    is_open_member_level: e
                                                })
                                                putShop(ItemId, { is_open_member_level: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                       </div> 

                                       <div style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>会员卡：</span>
                                        <Switch checked={ false} disabled
                                            onChange={(e) => {
                                                this.setState({
                                                    is_disable_goods_annex: e
                                                })
                                                putShop(ItemId, { is_disable_goods_annex: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div> 



                                </div>
                            </div>




                            <div style={{    flexDirection: "column"  }}> 
                                <div style={{ width: "100px" }}>
                                    收费插件
                                </div>

                                <div> 
                                    


                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>会员订阅：</span>
                                        <Switch checked={ false} disabled
                                            onChange={(e) => {
                                                this.setState({
                                                    is_disable_goods_annex: e
                                                })
                                                putShop(ItemId, { is_disable_goods_annex: e ? "1" : "0" }).then(res => {
                                                    this.GetShopData(1, "", language_id)
                                                })
                                            }} />
                                    </div>  

                                    
                                </div>
                            </div>



                        </Card>



                        <Card style={{ marginBottom: "10px" }}>


                         <div style={{ display: "flex" }}>
                                <div style={{ width: "100%" ,textAlign: "center", fontWeight: "bold", marginBottom: "20px", paddingBottom: "10px",borderBottom: "1px solid rgb(222, 222, 222) " }}>
                                    v6 严选
                                </div>

                            </div>



                            <div style={{ display: "flex" ,  flexDirection: "column" }}>
                                <div style={{ width: "100px" }}>
                                    功能
                                </div>
                                <div>



                                <div style={{ display: "flex", alignItems: "center",   marginTop: "15px"  }}>
                                    <span style={{ width: "150px", textAlign: "right" }}>严选：</span>
                                    <Switch    checked={is_open_strict}
                                        onChange={(e) => {
                                            this.setState({
                                                is_open_strict: e
                                            })
                                            putShop(ItemId, { is_open_strict: e ? 1 : 0 }).then(res => {
                                                this.GetShopData(1, "", language_id)
                                            })
                                        }} />
                                </div> 
                                </div>
                            </div> 

                        </Card>
















                        </div>


                        <div style={{ display:"flex",flexDirection:"row", justifyContent: "space-between"  }}  >

                        <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                            <div style={{ display: "flex",alignItems: "flex-end" }}>
                                <div style={{ width: "100px" }}>
                                    第三方视频平台
                                </div>
                                <div>

                                    <div style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
                                        <span style={{ width: "150px", textAlign: "right" }}>开启第三方视频平台：</span>
                                        <Switch checked={is_open_up_live}
                                            onChange={(e) => {
                                                if (!e) {
                                                    this.setState({
                                                        is_open_up_live: e
                                                    })
                                                    putShop(ItemId, { is_open_up_live: "0" }).then(res => {
                                                        this.GetShopData(1, "", language_id)
                                                    })
                                                } else {
                                                    this.setState({
                                                        up_live_visible: true
                                                    })
                                                }
                                            }} />
                                    </div>
                                    {/* {
                                        up_live_udid &&
                                        <div style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
                                            <span style={{ width: "50px", textAlign: "right" }}>udid：</span>
                                            <span>{up_live_udid}</span>
                                        </div>
                                    }

                                    {
                                        up_live_token &&
                                        <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                            <span style={{ width: "50px", textAlign: "right" }} >token：</span>
                                            <span>{up_live_token}</span>
                                        </div>
                                    } */}

                                    <Modal
                                        title="第三方视频平台"
                                        visible={this.state.up_live_visible}
                                        centered
                                        width={435}
                                        onOk={() => {
                                            this.setState({
                                                up_live_token_null: !up_live_token ? true : false,
                                                up_live_udid_null: !up_live_udid ? true : false
                                            })
                                            if (up_live_token && up_live_udid) {
                                                putShop(ItemId, { is_open_up_live: "1", up_live_token, up_live_udid }).then(res => {
                                                    this.setState({
                                                        is_open_up_live: true,
                                                        up_live_visible: false
                                                    }, () => {
                                                        this.GetShopData(1, "", language_id)
                                                    })
                                                })
                                            }

                                        }}
                                        onCancel={() => this.setState({ up_live_visible: false })}
                                    >
                                        {/* <div style={{ height: "60px" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <span style={{ width: "140px", textAlign: "right", marginRight: 5 }}>第三方视频平台uuid:</span>
                                                <Input
                                                    value={up_live_udid}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let value = e.target.value
                                                        this.setState({
                                                            up_live_udid: value,
                                                            up_live_udid_null: false
                                                        })
                                                    }} />
                                            </div>
                                            {
                                                this.state.up_live_udid_null &&
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ width: "140px", textAlign: "right", marginRight: 5 }} />
                                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>第三方视频平台uuid不能为空</span>
                                                </div>
                                            }
                                        </div> */}
                                        <div style={{ height: "60px" }}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span style={{ width: "140px", textAlign: "right", marginBottom: "5px" }}>第三方视频平台token:</span>
                                                <Input
                                                    value={up_live_token}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let value = e.target.value
                                                        this.setState({
                                                            up_live_token: value,
                                                            up_live_token_null: false
                                                        })
                                                    }} />
                                            </div>
                                            {
                                                this.state.up_live_token_null &&
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ width: "140px", textAlign: "right", marginRight: 5 }} />
                                                    <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>第三方视频平台token不能为空</span>
                                                </div>
                                            }
                                        </div>
                                    </Modal>


                                </div>
                            </div>
                        </Card>

                        </div>

                    </Modal>
                    <Modal
                        title="确认通过审核"
                        visible={auditVisible}
                        onOk={() => {
                            if (auditValue === "confirm") {
                                putShop(ItemId, { "is_audit": 1 }).then(res => {
                                    this.setState({
                                        chooseAll: true,
                                        auditVisible: false
                                    }, () => {
                                        this.GetShopData(1, "", this.state.language_id)
                                    })

                                })

                            } else {
                                message.error("输入错误！")
                            }
                        }}
                        onCancel={() => this.setState({ auditVisible: false, auditValue: "" })}
                        style={{ marginTop: "100px" }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ marginBottom: "15px" }}>请在输入框中输入"confirm"确认</span>
                            <Input
                                style={{ width: "200px", textAlign: 'center' }}
                                value={auditValue}
                                onChange={(e) => {
                                    e.persist()
                                    this.setState({
                                        auditValue: e.target.value.toLowerCase()
                                    })
                                }} />
                        </div>

                    </Modal>
                </div>
            );
        } else {
            return (<div />)
        }

    }
}

export default withRouter(Form.create()(ShopList))

