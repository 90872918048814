import React from "react";
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment-timezone';
import { Table, Icon, Form, Button, Switch, Select, Modal, Input, InputNumber, message, Tooltip    } from 'antd';
import { getBlindboxList, putCoupon } from "../../action/goodAction";
import { getShopList, getShopDetail } from "../../action/shopAction"
import {getLanguageList } from "../../action/authAction";
const { Option } = Select;
class PromotionalGoodsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shopList: "",
            meta: "",
            url: "",
            partner_id: "",
            visible: false,
            sortVisible: false,
            sortValue: "",
            itemData: "",
            closed: "",
            page: 1,
            is_eatmoon_show: false,
            LanguageData: "",
            language_id: 1, 

             url_1:"", 
             url_2:"",
             url_3:"",
             url_4:"", 
             url_5:"",


        };
        this.columns = [
            {
                title: "id",
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },
             

             {
                title: "封面",
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={record.image} alt="" />
                    )
                }
            },


            {
                title: "名称",
                width: '8%',
                align: "center",
                render: (record) => {
                    return <div style={{ display: "flex", alignItems: "center" }}>

                        { 
                             record.permanent_state === 1 &&  
                             <Icon type="stop" theme="filled" style={{ marginRight: "5px",color:"#ff5722" }} /> 

                        }


                        {
                            record.spu_id === 0 &&
                            <Icon type="shop" style={{ marginRight: "5px" }} />
                        }
                        <p style={{ margin: "0px" }}>{record.name}</p>
                    </div>
                }

            },

 
            

               {
                title: "价格",
                width: '8%',
                // align: "center",
                render: (record) => {
                    return <div style={{ display: "flex", alignItems: "center" }}> 
                        <p style={{ margin: "0px" }}>${record.price}</p>
                    </div>
                }
            },    

           

            {
                title: "开始时间",
                width: '6%',
                align: "center",
                render: (record) => {


                    //let start_at = record.start_at
                    //let end_at = record.end_at

                    let start_at = record.start_at
                    let end_at = record.end_at
                    let end_at_year = end_at.split(" ")
                    let end_at_time = new Date(end_at_year[0].split("/").reverse().join("/") + " " + end_at_year[1]).getTime()
                    let curDate = new Date(moment().tz('Australia/Sydney').format("YYYY/MM/DD HH:mm:ss")).getTime();
                    let isRed = end_at_time < curDate 
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{start_at.substring(0, start_at.length - 8)}</span> 
                    </div>
                }
            },


             {
                title: "结束时间",
                width: '6%',
                align: "center",
                render: (record) => {


                    //let start_at = record.start_at
                    //let end_at = record.end_at

                    let start_at = record.start_at
                    let end_at = record.end_at
                    let end_at_year = end_at.split(" ")
                    let end_at_time = new Date(end_at_year[0].split("/").reverse().join("/") + " " + end_at_year[1]).getTime()
                    let curDate = new Date(moment().tz('Australia/Sydney').format("YYYY/MM/DD HH:mm:ss")).getTime();
                    let isRed = end_at_time < curDate 
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{end_at.substring(0, end_at.length - 8)}</span> 
                    </div>
                }
            },







            // {
            //     title: "活动状态",
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <div>
            //                 {
            //                     record.permanent_state === 1 ?
            //                         <span style={{ color: "#ff0000" }}>已终止</span> :
            //                         record.end_status ?
            //                             <span style={{ color: "#ff0000" }}>已结束</span> :
            //                             <span style={{ color: "green" }}>正常</span>
            //                 }
            //             </div>

            //         )
            //     }
            // },


            // {
            //     title: "上架",
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {

            //         if(record.permanent_state != 1){
            //             let end_at = record.end_at
            //             let end_at_year = end_at.split(" ")
            //             let end_at_time = new Date(end_at_year[0].split("/").reverse().join("/") + " " + end_at_year[1]).getTime()
            //             let curDate = new Date(moment().tz('Australia/Sydney').format("YYYY/MM/DD HH:mm:ss")).getTime();
            //             let isRed = end_at_time < curDate
            //             return (
            //                 <Switch
            //                     //disabled={record.permanent_state === 1 || (isRed && record.state === 0)} 
            //                     checked={record.state === 1}
            //                     onChange={() => this.onChange(record)} />
            //             )

            //         }
                    
            //     }
            // },


            




            {
                title: "显示",
                width: '5%',
                align: "center",
                render: (record) => { 
                    let checked = false; 
                    if(record.is_show === 1){
                        checked = true;
                    }else{
                        checked = false;
                    } 
                    return <Switch disabled checked={checked} />
  
                }
            },



 

           
            {
                title: "操作",
                width: '4%',
                align: "center",
                render: (record) => {
                    // return record.end_status ?
                    //     null :

                     if( record.permanent_state != 1){   
                        return <div style={{ display: "flex", justifyContent: "center" }}> 
                        
                                {/*<Button style={{ margin: "5px" }}
                                    onClick={() => {
                                        this.setState({
                                            visible: true,
                                            itemData: record
                                        })
                                    }} >
                                    终止
                                </Button> */}


                                 <Button
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {
                                        this.props.history.push("/BlindboxDetail/" + record.id)
                                    }}>查看</Button>



                        </div>
                    }else{ 
                         return   <span style={{ color: "red" }}>已终止</span> 
                    }
                    
                },
            },
        ];
    }


    async componentDidMount() {


        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({ LanguageData }, () => {
            this.HandleGetData(LanguageData[1].id)
        })



        let resp = await getShopList("", "")
        let data = resp.data.data
        console.log(data)
        this.setState({
            shopList: data,
            partner_id: "",//data[0].id //默认显示全部店铺
        }, () => {
            //this.HandleGetData()
        })
    }

    async HandleGetData() {


        let { page, partner_id, url ,language_id} = this.state; 
        let res = await getBlindboxList(page, partner_id, url || "" ,language_id)


        let shopData = await getShopDetail(partner_id, 1)
        this.setState({
            data: res.data.data,
            is_eatmoon_show: shopData.data.is_eatmoon_show === 1,
            meta: res.data.meta.pagination
        })
    }


    onChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.state = item.state === 1 ? "0" : "1"
        putCoupon(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }
    onShowChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.is_show = item.is_show === 1 ? "0" : "1"
        putCoupon(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }


    handleOverOff = () => {
        let item = this.state.itemData
        let values = {}
        values.spu_id = item.spu_id
        values.permanent_state = "1"
        putCoupon(values, item.id).then(res => {
            this.setState({
                visible: false,
                closed: ""
            })
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }


    onMoonChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.is_moon_show = item.is_moon_show === 1 ? "0" : "1"
        putCoupon(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }



    onEatMoonChange = (item) => {
        let values = {}
        values.spu_id = item.spu_id
        values.is_eatmoon_show = item.is_eatmoon_show === 1 ? "0" : "1"
        putCoupon(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }
    onLockChange = (item) => {
        let values = {}
        // values.spu_id = item.spu_id
        values.is_lock = item.is_lock === 1 ? "0" : "1"
        putCoupon(values, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })

    }

    render() {
        let { LanguageData,shopList, meta, url, closed, partner_id, sortVisible, sortValue , url_1,url_2,url_3,url_4,url_5} = this.state
        return (
            <div>
                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: 'none', marginBottom: "10px" }}>
                    <div>
                        

                        {
                            LanguageData ? <Select
                                style={{ width: "100px" }}
                                defaultValue={LanguageData[1].name}
                                onChange={(e) => {

                                    console.log("e=========",e); 
                                    this.setState({  language_id:e}, 
                                          () => {  this.HandleGetData(e)    } 
                                    )  
                                    
                                }}
                            >
                                {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                            </Select> : <div />
                        } 

                    </div>
                    
                </div>






                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'none', marginBottom: "10px" }}>
                    
                       <Button
                            style={{ marginRight: "5px", backgroundColor:  url === "" ? "#1DA57A" : "", color:   url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "",
                                    url_1:"",
                                    url_2:"",
                                    url_3:"",
                                    url_4:"",
                                    url_5:"",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            全部
                        </Button>



                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_1 === "&state=1" ? "#1DA57A" : "", color: url_1 === "&state=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                   partner_id: partner_id,
                                   url: "&state=1" + url_2 +  url_3 + url_4 + url_5 ,
                                   url_1: "&state=1",
                                   page:1,
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            已上架
                        </Button>

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_1 === "&state=0" ? "#1DA57A" : "", color: url_1 === "&state=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                   url: "&state=0"  + url_2 +  url_3 + url_4 + url_5 ,
                                   url_1:"&state=0", 
                                   page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            已下架
                        </Button>

                         

                        

                </div>



 
                 

                <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "flex-start", backgroundColor: 'none', marginBottom: "15px" }}>
                    
                        
                        <Button
                            style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:  "#fff"   }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "",
                                    page:1
                                }, () => {
                                    //this.HandleGetData()
                                })
                            }}>
                            排序
                        </Button>

 
                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_5 === "&orderby=1" ? "#1DA57A" : "", color: url_5 === "&orderby=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                   url: "&orderby=1"  + url_1 +  url_2 + url_3 + url_4 ,
                                   url_5:"&orderby=1",
                                   page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            创建时间
                        </Button>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_5 === "&orderby=2" ? "#1DA57A" : "", color: url_5 === "&orderby=2" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    partner_id: partner_id,
                                    url: "&orderby=2"  + url_1 +  url_2 + url_3 + url_4 ,
                                    url_5:"&orderby=2",
                                    page:1
                                }, () => {
                                    this.HandleGetData()
                                })
                            }}>
                            开始时间
                        </Button>

                </div>






                <div  className="waperBox">
                <Table
                    rowKey='id'
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.setState({
                            page: e.current
                        }, () => {
                            this.HandleGetData()
                        })

                    }}
                />
                </div>

                
                <Modal
                    title="确认终止"
                    visible={this.state.visible}
                    onOk={() => {
                        if (closed === "confirm") {
                            this.handleOverOff()
                        } else {
                            message.error("输入错误！")
                        }
                    }}
                    onCancel={() => this.setState({ visible: false, closed: "" })}
                    style={{ marginTop: "100px" }}
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <span style={{ marginBottom: "15px" }}>请在输入框中输入"confirm"确认</span>
                        <Input
                            style={{ width: "200px", textAlign: 'center' }}
                            value={closed}
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    closed: e.target.value.toLowerCase()
                                })
                            }} />
                    </div>

                </Modal>

                <Modal
                    title="编辑排序"
                    visible={this.state.sortVisible}
                    onCancel={() => this.setState({ sortVisible: false, itemData: "" })}
                    style={{ marginTop: "100px" }}
                    onOk={() => {
                        let { itemData, sortValue } = this.state
                        let data = { "system_sort": sortValue }
                        putCoupon(data, itemData.id).then(res => {
                            this.HandleGetData()
                            this.setState({ sortVisible: false, itemData: "" })
                        })
                    }}
                >
                    <div style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                        <span>排序：</span>
                        <InputNumber
                            style={{ width: "300px", textAlign: 'center' }}
                            value={sortValue}
                            onChange={(e) => {
                                this.setState({
                                    sortValue: e
                                })
                            }} />
                    </div>

                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(PromotionalGoodsList))

