import React from "react";
import { withRouter } from 'react-router-dom'
import { Input, Table, Form, Select, Button, DatePicker, Modal, Icon, Popover } from 'antd';
import { GetOrderList, OrderRefund, OrderWaitRefund } from "../../action/authAction";

import { getOrderBlindboxList, getOrderBlindboxDetail } from "../../action/goodAction";

import { getShopList } from "../../action/shopAction"
import moment from 'moment';
const { Search, TextArea } = Input;
const { RangePicker } = DatePicker;

class BlindBoxOrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            shopList: "",
            defaultShop: "",
            defaultShopId: "",
            meta: "",
            url: "",
            url_1:"",
            url_2:"",
            url_3:"",
            url_4:"",
            one_url: "",
            two_url: "",
            visible: false,
            ItemId: "",
            refund_message: "",
            consumption_type:null,//消费方式

        };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },

             


            {
                title: '订单编号',
                dataIndex: 'order_sn',
                width: '7%',
                align: "center",
            },


            {
                title: '封面',
                width: '10%',
                align: "center",
                render: (record) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={record.blindstore?record.blindstore.image:"-"} alt="" />
                    )
                }
            },
             


            {
                title: '名称',
                width: '10%',
                align: "center",
                render: (text, record) => { 
                    return <div style={{ display: "flex" }}> 
                        <div style= {{ flex: "1 1 0%" ,display: "flex" }}>
                            {record.blindstore?record.blindstore.name:"-"}
                        </div>
                    </div>
                      
                }
            },
             

            {
                title: '付款金额',
                dataIndex: 'price',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>${record}</span>
                }
            },

 
     

            {
                title: '下单时间',
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
            },
 

            {
                title: '操作',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return <Popover
                        style={{ width: 500 }}
                        content={
                            <div style={{ display: "flex" }}>
                                <Button
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {
                                        this.props.history.push("/BlindBoxOrderDetail/" + record.id)
                                    }}>查看</Button>
                                    
                                    {/*
                                        record.is_pay === 1 &&
                                        <Button
                                            onClick={() => {
                                                this.setState({
                                                    visible: true,
                                                    ItemId: record.id,
                                                    refund_message: ""
                                                })
                                            }}>退款</Button>
                                    */}
                                    
                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover>
                }
            },
        ];
    }


    async componentDidMount() {
        let resp = await getShopList(1, "")
        this.setState({
            url: "",
            defaultShop: "",
            defaultShopId: "",
            shopList: resp.data.data,
        }, () => {
            this.HandleGetData(this.state.defaultShopId, 1)
        })

    }

    async HandleGetData(shopId, page) {
        let res = await getOrderBlindboxList(this.state.url, shopId, page);
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination,
            ItemId: "",
            visible: false
        })
    }



    render() {
        const columns = this.columns.map(col => {
            return {
                ...col,
                onCell: record => ({
                    record,
                    title: col.title,
                }),
            };
        });
        const { Option } = Select;
        const { consumption_type , data, meta, shopList, defaultShop, defaultShopId, url, url_1,url_2,url_3, url_4 ,one_url, two_url, visible, ItemId, refund_message } = this.state
        return (
            <div>
                {
                    !!data &&
                    <div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                             
                            <Search
                                style={{ width: 300, marginRight: "10px", marginBottom: "5px" }}
                                placeholder={'请输入订单号'}
                                enterButton={'查询'}
                                onSearch={(val) => {
                                    this.setState({
                                        url: "&order_sn=" + val
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}
                            />
                            <Search
                                style={{ width: 300, marginBottom: "5px", marginRight: "5px" }}
                                placeholder={'请输入手机号'}
                                enterButton={'查询'}
                                onSearch={(val) => {
                                    this.setState({
                                        url: "&phone=" + val
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}
                            />
                            <RangePicker
                                style={{ marginBottom: "5px" }}
                                format="YYYY-MM-DD"
                                onChange={(value) => {
                                    let start_at = moment(value[0]).format("YYYY-MM-DD") + " 00:00:00"
                                    let end_at = moment(value[1]).format("YYYY-MM-DD") + " 23:59:59"
                                    this.setState({
                                        url: "&start_at=" + start_at + "&end_at=" + end_at
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }} />
                        </div>
                         

  


                        <div style={{ marginBottom: "5px" }}>



                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#1DA57A"  , color:   "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        // url: "",
                                        // one_url: "",
                                        // two_url: ""
                                    }, () => {
                                        //this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                状态
                            </Button>

 

                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_pay=1" ? "#1DA57A" : "#fff", color: url_3 === "&is_pay=1" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "付款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.pay_at && record.pay_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url: url_1 + url_2 + "&is_pay=1"  +url_4,
                                        url_3: "&is_pay=1"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                已付款
                            </Button>


                              

                           

                           { (consumption_type == null  ||   consumption_type === 0 ) && 
                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&order_status=for_consumption" ? "#1DA57A" : "#fff", color: url_3 === "&order_status=for_consumption" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "付款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.pay_at && record.pay_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:  "&order_status=for_consumption" + url_1 + url_2   +url_4,
                                        url_3: "&order_status=for_consumption"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                未抽取
                            </Button>
                            }



                            {/*
                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_finish=1" ? "#1DA57A" : "#fff", color: url_3 === "&is_finish=1" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "付款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.pay_at && record.pay_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:  "&is_finish=1" + url_1 + url_2  +url_4,
                                        url_3: "&is_finish=1"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                已完成
                            </Button>
                            */}
                            
 
                            {/*<Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_apply_refund=1" ? "#1DA57A" : "#fff", color: url_3 === "&is_apply_refund=1" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "退款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.refund_at && record.refund_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:   "&is_apply_refund=1" + url_1 + url_2  +url_4,
                                        url_3: "&is_apply_refund=1"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                退款中
                            </Button>

 

                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&status=9" ? "#1DA57A" : "#fff", color: url_3 === "&status=9" ? "#fff" : "#000" }}
                                onClick={() => {
                                    this.columns.splice(5, 1, {
                                        title: "退款时间",
                                        width: '8%',
                                        align: "center",
                                        render: (record) => {
                                            return record.refund_at && record.refund_at.date.substring(0, 19)
                                        }
                                    })
                                    this.setState({
                                        url:   "&status=9" + url_1 + url_2  +url_4,
                                        url_3: "&status=9"
                                    }, () => {
                                        this.HandleGetData(defaultShopId, 1)
                                    })
                                }}>
                                已退款
                            </Button>
                            */}



                        </div>



                        


                        <div className="waperBox">
                            <Table
                                rowKey='id'
                                // bordered
                                dataSource={data}
                                columns={columns}
                                pagination={{
                                    pageSize: meta.per_page,
                                    total: meta.total
                                }}
                                onChange={(e) => {
                                    this.HandleGetData(defaultShopId, e.current)
                                }}
                            />
                        </div>
                    </div>
                }
                <Modal
                    title="确认退款"
                    visible={visible}
                    onCancel={() => {
                        this.setState({
                            visible: false,
                            ItemId: "",
                            refund_message: ""
                        })
                    }}
                    onOk={() => {
                        OrderRefund(ItemId, { refund_message }).then(res => {

                           

                        setTimeout(() => {
                                 this.setState({
                                    //ItemId: "",
                                    //refundvisible: false
                                }, () => { 
                                     this.HandleGetData(defaultShopId, 1)
                                })
                         }, 3000)




                        }).catch(error => {
                            this.setState({
                                ItemId: "",
                                visible: false
                            })
                        })
                    }}
                >
                    <TextArea
                        value={refund_message}
                        placeholder="请输入管理员备注（客户不可见）"
                        onChange={(e) => {
                            this.setState({
                                refund_message: e.target.value
                            })
                        }}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(BlindBoxOrderList))

