import { Axios } from "../util/request"
import { API } from "../util/api";


import { type } from "../util/varibales";

export const SetMenuName = (name) => {
    return (dispatch) => {
        dispatch({
            type: type.SWITCH_MENU,
            payload: name
        });
    }
}

// 登录
export const login = (data) => {
    return Axios.ajax({
        url: API.auth.LOGIN,
        method: "post",
        data
    })
}


//获取用户信息
export const getPartner = () => {
    return Axios.ajax({
        url: API.auth.partnerAddress,
    })
}



// 修改用户信息
export const UpdateShopMessage = (data) => {
    return Axios.ajax({
        url: API.auth.PARTNER + API.auth.UPDATE,
        method: "post",
        tips: "修改成功",
        data
    })
}

// 获取系统设置
export const GetSysTemSet = () => {
    return Axios.ajax({
        url: API.set,
        method: "get",
    })
}

// 修改系统设置
export const UpdateSysTemSet = (data) => {
    return Axios.ajax({
        url: API.set + "/1",
        method: "put",
        tips: "修改成功",
        data
    })
}
// 获取系统设置
export const GetConfigSet = () => {
    return Axios.ajax({
        url: API.config,
        method: "get",
    })
}

// 修改系统设置
export const UpdateConfigSet = (data) => {
    return Axios.ajax({
        url: API.setConfigs,
        method: "post",
        tips: "修改成功",
        data
    })
}



// 获取文档开关的系统设置
export const GetConfigSetDocument = () => {
    return Axios.ajax({
        url: API.getConfig+"/is_document_enable",
        method: "get",
    })
}

// 修改文档开关的系统设置
export const UpdateConfigSetDocument = (data) => {
    return Axios.ajax({
        url: API.setConfig + "/is_document_enable",
        method: "post",
        tips: "修改成功",
        data
    })
}









// 获取祝福参数验证码
export const sendUpdatePayParamCodeMail = () => {
    return Axios.ajax({
        url: API.sendUpdatePayParamCodeMail,
        method: "get",
        tips: "发送成功",
    })
}


// 获取商家提现记录
export const GetWithdrawLog = () => {
    return Axios.ajax({
        url: API.withdrawLog.withdrawLog,
        method: "get",
    })
}

// 获取商家提现详情
export const GetWithdrawDetail = (id) => {
    return Axios.ajax({
        url: API.withdrawLog.withdrawLog + "/" + id,
        method: "get",
    })
}

// 商家提现处理
export const DisposeWithdraw = (data, id) => {
    return Axios.ajax({
        url: API.withdrawLog.withdrawLog + API.withdrawLog.dispose + "/" + id,
        method: "post",
        tips: "处理成功",
        data
    })
}

// 获取用户提现记录
export const GetUserWithdrawLog = () => {
    return Axios.ajax({
        url: API.withdrawLog.userWithdrawLog,
        method: "get",
    })
}
// 获取用户退款记录
export const GetUserRefund = (url, page) => {
    return Axios.ajax({
        url: API.withdrawLog.userWithdrawLog + "?is_order_refund=1" + url + "&page=" + page,
        method: "get",
    })
}


// 获取用户退款申请记录
export const GetUserOrderRefund = (url, page) => {
    return Axios.ajax({
        url: API.UserOrderRefundLog.UserOrderRefundLog + "?is_order_refund=1" + url + "&page=" + page,
        method: "get",
    })
}


// 获取用户退款申请详情
export const GetUserOrderRefundDetail = (id) => {
    return Axios.ajax({
        url: API.UserOrderRefundLog.UserOrderRefundLog + "/" + id,
        method: "get",
    })
}


// 用户提现处理
export const DisposeUserOrderRefund = (data, id) => {
    return Axios.ajax({
        url: API.UserOrderRefundLog.UserOrderRefundLog + API.UserOrderRefundLog.dispose + "/" + id,
        method: "post",
        tips: "处理成功",
        data
    })
}







// 获取用户提现详情
export const GetUserWithdrawDetail = (id) => {
    return Axios.ajax({
        url: API.withdrawLog.userWithdrawLog + "/" + id,
        method: "get",
    })
}

// 用户提现处理
export const DisposeUserWithdraw = (data, id) => {
    return Axios.ajax({
        url: API.withdrawLog.userWithdrawLog + API.withdrawLog.dispose + "/" + id,
        method: "post",
        tips: "处理成功",
        data
    })
}

// 新建公告
export const AddAnnouncement = (data) => {
    return Axios.ajax({
        url: API.systemAnnouncement,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取公告列表
export const getAnnouncementList = (language_id) => {
    return Axios.ajax({
        url: API.systemAnnouncement + "?request_language_id=" + language_id,
        method: "get"
    })
}
// 获取公告告详情
export const getAnnouncementDeatil = (id, language_id) => {
    return Axios.ajax({
        url: API.systemAnnouncement + "/" + id + "?request_language_id=" + language_id,
        method: "get"
    })
}
//修改公告
export const UpdateAnnouncement = (data, id) => {
    return Axios.ajax({
        url: API.systemAnnouncement + "/" + id,
        method: "post",
        tips: "修改成功",
        data
    })
}

// 删除公告
export const deleteAnnouncement = (id) => {
    return Axios.ajax({
        url: API.systemAnnouncement + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

// 获取公告内容
export const getAnnouncementContent = (url) => {
    return Axios.ajax({
        url: url,
        method: "get",
        noToken: true
    })
}

// 获取用户协议
export const getUserAgreement = () => {
    return Axios.ajax({
        url: "userAgreement/1",
        method: "get"
    })
}

//修改用户协议
export const UpdateUserAgreement = (data) => {
    return Axios.ajax({
        url: "userAgreement/1",
        method: "put",
        tips: "修改成功",
        data
    })
}

// 获取用户隐私
export const getUserPrivacy = () => {
    return Axios.ajax({
        url: "userPrivacy/1",
        method: "get"
    })
}

//修改用户隐私
export const UpdateUserPrivacy = (data) => {
    return Axios.ajax({
        url: "userPrivacy/1",
        method: "put",
        tips: "修改成功",
        data
    })
}


// 获取电话记录
export const getPhoneLogList = () => {
    return Axios.ajax({
        url: API.importPartner.importPartner + API.importPartner.has_call,
        method: "get",
    })
}

// 获取用户列表
export const getUserList = (num, phone,url) => {
    return Axios.ajax({
        url: API.user.user + API.user.include + API.user.page + num + API.user.phone + phone + url,
        method: "get",
    })
}
// 获取用户反馈列表
export const getContactUsList = (page) => {
    return Axios.ajax({
        url: API.user.contactUs + "?page=" + page,
        method: "get",
    })
}


//修改用户反馈
export const UpdateContactUs = (data, id) => {
    return Axios.ajax({
        url: API.user.contactUs + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}
//获取用户详情
export const getUserDetail = (id) => {
    return Axios.ajax({
        url: `/user/${id}?include=partner`,
        method: "get",
    })
}

//修改用户密码
export const UpdateUser = (data, id) => {
    return Axios.ajax({
        url: "user/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}

// 新建套餐
export const AddPackage = (data) => {
    return Axios.ajax({
        url: API.package,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取申请套餐记录列表
export const getApplyPackageList = (language_id) => {
    return Axios.ajax({
        url: API.partnerApplyPackage,
        method: "get"
    })
}
//修改申请套餐
export const UpdateApplyPackage = (id, data) => {
    return Axios.ajax({
        url: API.partnerApplyPackage + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}


//删除申请套餐
export const DeleteApplyPackage = (id, data) => {
    return Axios.ajax({
        url: API.partnerApplyPackage + "/" + id,
        method: "delete",
        tips: "删除成功",
        data
    })
}


// 获取套餐列表
export const getPackageShowList = (language_id) => {
    return Axios.ajax({
        url: API.package + "?is_show=1&request_language_id=" + language_id,
        method: "get"
    })
}



// 获取套餐列表
export const getPackageList = (language_id) => {
    return Axios.ajax({
        url: API.package + "?request_language_id=" + language_id,
        method: "get"
    })
}
// 获取套餐详情
export const getPackageDetail = (id, language_id) => {
    return Axios.ajax({
        url: API.package + "/" + id + "?request_language_id=" + language_id,
        method: "get"
    })
}
//修改套餐
export const UpdatePackage = (data, id) => {
    return Axios.ajax({
        url: API.package + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}

// 删除套餐
export const deletePackage = (id) => {
    return Axios.ajax({
        url: API.package + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}


// 获取插件列表
export const getPlugInList = () => {
    return Axios.ajax({
        url: API.plugin,
        method: "get"
    })
}

// 获取插件详情
export const getPlugInDeatil = (id) => {
    return Axios.ajax({
        url: API.plugin + "/" + id,
        method: "get"
    })
}
export const addPlugIn = (data) => {
    return Axios.ajax({
        url: API.plugin,
        method: "post",
        tips: "提交成功",
        data
    })
}

//修改插件
export const UpdatePlugIn = (data, id) => {
    return Axios.ajax({
        url: API.plugin + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}

export const delPlugIn = (id) => {
    return Axios.ajax({
        url: API.plugin + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}


// 新建管理员
export const AddAdmin = (data) => {
    return Axios.ajax({
        url: API.systemUser,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取管理员列表
export const GetAdminList = () => {
    return Axios.ajax({
        url: API.systemUser,
        method: "get"
    })
}


//获取管理员信息
export const getAdminDetail = (id) => {
    return Axios.ajax({
        url: API.systemUser + "/" + id,
    })
}

// 修改管理员
export const putAdmin = (data, id) => {
    return Axios.ajax({
        url: API.systemUser + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}

// 删除管理员
export const deleteAdmin = (id) => {
    return Axios.ajax({
        url: API.systemUser + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}


// 获取订单列表
export const GetOrderList = (data, shopId, page) => {
    return Axios.ajax({
        url: API.order.order + API.order.include + "&partner_id=" + shopId + data + "&page=" + page,
        method: "get"
    })
}

// 订单退款
export const OrderRefund = (id, data) => {
    return Axios.ajax({
        url: API.orderRefund + id,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 订单部分退款
export const OrderRefundGoods = (id, data) => {
    return Axios.ajax({
        url: API.orderRefundGoods + id,
        method: "post",
        tips: "提交成功",
        data
    })
}



// 订单退款审核
export const OrderWaitRefund = (id, data) => {
    return Axios.ajax({
        url: API.waitRefund + id,
        method: "post",
        tips: "提交成功",
        data
    })
}



export const GetOrderListE = (page,id) => {
    return Axios.ajax({
        url: `/order?include=user,orderSpu,partner&user_id=${id}&page=${page}`,
        method: "get"
    })
}

export const getOrderDetail = (id) => {
    return Axios.ajax({
        url: `/order/${id}?is_finish=0&include=user,orderSpu`,
        method: "get"
    })
}


//获取元宝兑换订单 
export const GetYuanbaoOrderList = (data, shopId, page) => {
    return Axios.ajax({
        url: API.orderYuanbao + "?partner_id=" + shopId + data + "&page=" + page,
        method: "get"
    })
}

export const getYuanbaoOrderDetail = (id) => {
    return Axios.ajax({
        url: `/orderYuanbao/${id}?is_finish=0&include=user`,
        method: "get"
    })
}



//获取用户的订阅记录

export const GetsubscriptionList = (page,id) => {
    return Axios.ajax({
        url: `/userSubscriptionLog?include=user&user_id=${id}&page=${page}`,
        method: "get"
    })
}

//获取用户的积点记录
export const GetScoreList = (page,id) => {
    return Axios.ajax({
        url: `/userScoreLog?include=user&user_id=${id}&page=${page}`,
        method: "get"
    })
}


//获取用户的元宝记录
export const GetYuanbaoList = (page,id) => {
    return Axios.ajax({
        url: `/userYuanbaoLog?include=user&user_id=${id}&page=${page}`,
        method: "get"
    })
}


//获取用户的小游戏记录
export const GetTurntableTicketLogList = (page,id) => {
    return Axios.ajax({
        url: `/userTurntableTicketLog?include=user&user_id=${id}&page=${page}`,
        method: "get"
    })
}



export const GetTurntableGoldTicketLogList = (page,id) => {
    return Axios.ajax({
        url: `/userTurntableGoldTicketLog?include=user&user_id=${id}&page=${page}`,
        method: "get"
    })
}


//获取用户的盲盒抽取记录
export const GetBlindboxTicketLogList = (page,id) => {
    return Axios.ajax({
        url: `/userBlindstoreTicketLog?include=user&user_id=${id}&page=${page}`,
        method: "get"
    })
}






// 获取上帝密码
export const GetgodPassword = (id) => {
    return Axios.ajax({
        url: API.godPassword,
        method: "get"
    })
}

//获取语言列表
export const getLanguageList = () => {
    return Axios.ajax({
        url: API.language,
        method: "get"
    })
}




// 获取费用流水
export const GetPartnerMonthlyFeet = (page, url, date) => {
    return Axios.ajax({
        url: API.partnerPackageFeeLog + "?page=" + page + url  + "&created_at=" + date,
        method: "get",
    })
}



// 获取结算清单
export const GetPartnerStatementList = (page, url, date) => {
    return Axios.ajax({
        url: API.partnerPackageStatementLog + "?page=" + page + url  + "&created_at=" + date,
        method: "get",
    })
}



// 获取结算清单详情
export const GetPartnerStatementDetail = (id) => {
    return Axios.ajax({
        url: API.partnerPackageStatementLog + "/" + id ,
        //url: `${API.BASEURL}${API.order.order}/${id}${API.order.include}&request_language_id=${getLangType()}`,
        method: "get",
    })
}







// 结算清单更改支付状态
export const putPartnerStatementPay = (id,data) => {
    return Axios.ajax({
        url: API.partnerPackageStatementLog + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}

// 结算清单更改结算状态
export const putPartnerStatementSettle = ( id ,data) => {
    return Axios.ajax({
        url: API.partnerPackageStatementLog + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}


// 生成订单
export const createStatement = (data) => {
    return Axios.ajax({
        url: API.createStatement  ,
        method: "post",
        data
    })
}

//删除结算清单
export const deleteStatement = (id) => {
    return Axios.ajax({
        url: API.partnerPackageStatementLog + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}







// 新建语言
export const AddLang = (data) => {
    return Axios.ajax({
        url: API.language,
        method: "post",
        tips: "提交成功",
        data
    })
}

//修改
export const editLang = (id, data) => {
    return Axios.ajax({
        url: API.language + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}

//删除语言
export const deleteLanguage = (id) => {
    return Axios.ajax({
        url: API.language + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}


//
export const QueryOrders = () => {
    return Axios.ajax({
        url: "partnerOrder?include=partner,partnerSubscription.prod",
        method: "get"
    })
}

export const myOrderDetail = (id) => {
    return Axios.ajax({
        url: `partnerOrder/${id}`,
        method: "get"
    })
}


export const QuerySubscrption = () => {
    return Axios.ajax({
        url: "partnerSubscription?include=partner,partnerOrder,prod,package,addon",
        method: "get"
    })
}

export const mySubscrDetail = (id) => {
    return Axios.ajax({
        url: `partnerSubscription/${id}`,
        method: "get"
    })
}


export const QueryStatistics = () => {
    return Axios.ajax({
        url: API.dataSum,
        method: "get"
    })
}


//系统积分兑换项/////////////////////////////////////////////


// 获取商家等级变更列表
export const getUserPointsExchangeOptionList = () => {
    return Axios.ajax({
        url: API.points.systemPointsExchangeOption,
        method: "get"
    })
}

// 获取商家等级变更列表
export const getUserPointsExchangeOptionDeletedList = () => {
    return Axios.ajax({
        url: API.points.systemPointsExchangeOption+"?is_deleted=1",
        method: "get"
    })
}


// 添加商家积分兑换项
export const recoverPointsExchangeOption = (id) => {
    return Axios.ajax({
        url: API.points.systemPointsExchangeOptionRecover+"?id="+id,
        method: "get",
        tips: "恢复成功", 
    })
}





// 添加商家积分兑换项
export const addPointsExchangeOption = (data) => {
    return Axios.ajax({
        url: API.points.systemPointsExchangeOption,
        method: "post",
        tips: "提交成功",
        data
    })
}

 
// 添加商家积分兑换项
export const putPointsExchangeOption = (data,id) => {
    return Axios.ajax({
        url: API.points.systemPointsExchangeOption+"/"+id,
        method: "put",
        tips: "提交成功",
        data
    })
}


// 删除商家积分兑换项
export const delPointsExchangeOption = (id) => {
    return Axios.ajax({
        url: API.points.systemPointsExchangeOption+"/"+id,
        method: "delete",
        tips: "归档成功",
        
    })
}



///////////////////////////////////////////////////


