import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Select } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { AddStrictcategory } from "../../action/shopAction";
import { getLanguageList } from "../../action/authAction"


class NewStrict extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            is_show: true,
            disable_language: true,
            LanguageData: "",
            editorState: BraftEditor.createEditorState(null),
        }
    }

    async componentDidMount() {
        let LanguageData = await getLanguageList()
        LanguageData = LanguageData.data.data
        this.setState({
            LanguageData
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.is_show = this.state.is_show ? "1" : "0"
            values.disable_language = this.state.disable_language ? "0" : "1"
            //values.content = this.state.editorState.toHTML()
            if (!err) {
                AddStrictcategory(values).then(res => {
                    this.props.history.push("/StrictCategoryList")
                })
            }

        });
    };


    handleChange = (editorState) => {
        this.setState({ editorState })
    }




    render() {
        let { is_show, disable_language, LanguageData } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label="选择录入的语言"
                >
                    {getFieldDecorator('request_language_id', {
                        rules: [{ required: true, message: '请选择语言!' }],
                        initialValue: LanguageData && LanguageData[1].id
                    })(
                        <Select>
                            {LanguageData && LanguageData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label="标题"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: false }],
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label="排序"
                >
                    {getFieldDecorator('sort', {
                        initialValue: "0"
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="是否显示">
                    <Switch checked={is_show} onChange={() => {
                        this.setState({
                            is_show: !is_show
                        })
                    }} />
                </Form.Item>

 
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewStrict))
